import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Box, Button, CircularProgress, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { accountController } from '../../../controllers';
import UseAuth, { AuthContext } from '../../../hooks/useAuth';
import { ChangePassword, Login } from '../../../models/Login';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { LocalStore } from '../../../helpers/localStorage';
import { LanguageContext } from '../../../contexts/useLanguage';
import toast from 'react-hot-toast';
import { WebLanguage } from '../../../constants';

interface State {
    showPassword: boolean;
    showRePassword: boolean;
    isLoading: boolean;
    tokenId: string | null;
    resStatus: string;
}
interface ChangePassValue {
    password: string;
    repass: string;
}

export default function ChangePassForm() {
    const param = useParams();
    const { changePassword } = useContext(AuthContext);
    const [state, setState] = useState<State>({
        showPassword: false,
        showRePassword: false,
        isLoading: false,
        resStatus: '',
        tokenId: '',
    });
    const { t, language } = useContext(LanguageContext);

    const RegisterSchema = Yup.object().shape({
        password: Yup.string()
            .required(t('validation:passwordIsRequired'))
            .min(8, t('validation:passwordsMustHaveAtLeast8Characters')),
        repass: Yup.string()
            .required(t('validation:pleaseRetypeYourPass'))
            .min(8, t('validation:passwordsMustHaveAtLeast8Characters'))
            .oneOf([Yup.ref('password')], t('validation:theNewPasswordAndTheConfirmPasswordAreNotMatching')),
    });

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            password: '',
            repass: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            const changeInfo: ChangePassword = {
                id: state.tokenId!,
                password: values.repass,
                token: param.token!,
            };
            setTimeout(() => {
                changePassword(changeInfo)
                    .then((res) => {
                        setTimeout(() => {
                            navigate(`/?email=${res}`, { replace: true });
                            toast.success(t('notification:changePasswordSuccessfully'));
                        }, 1000);
                    })
                    .catch((err) => {
                        if (err.response.status !== 500) toast.error(t('notification:fail'));
                    });
                setSubmitting(false);
            }, 1000);
        },
    });
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setSubmitting } = formik;

    useEffect(() => {
        if (param.token) {
            LocalStore.deleteKey('jwt');
            accountController
                .checkTokenIsValid(param.token)
                .then((res) => {
                    setState((prev) => ({ ...prev, isLoading: false, tokenId: res }));
                })
                .catch((res) => {
                    res.status == 410
                        ? setState((prev) => ({ ...prev, isLoading: false, resStatus: t('validation:tokenIsExpired') }))
                        : setState((prev) => ({
                              ...prev,
                              isLoading: false,
                              resStatus: t('validation:tokenIsInvalid'),
                          }));
                });
        }
    }, []);

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };
    const handleClickShowRePassword = () => {
        setState({
            ...state,
            showRePassword: !state.showRePassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return state.isLoading ? (
        <Box sx={{ flex: 1, textAlign: 'center', pt: 10 }}>
            <CircularProgress />
        </Box>
    ) : state.tokenId ? (
        <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: { md: '60px 24px', lg: '80px 24px', xs: '15px 20px', sm: '40px 20px' },
                        transition: 'all .5s',
                        '& #outlined-required-helper-text': {
                            position: 'absolute',
                            top: '35px',
                            left: 0,
                            fontSize: '12px',
                        },
                        '& #outlined-required1-helper-text': {
                            position: 'absolute',
                            top: '35px',
                            left: 0,
                            fontSize: '12px',
                        },
                    }}
                >
                    <Typography variant="h3">{t('loginPage:text.changePassword')}</Typography>

                    <TextField
                        id="outlined-required"
                        label={t('loginPage:text.newPassword')}
                        placeholder={t('input:enterYourPassword')}
                        fullWidth
                        size="small"
                        type={state.showPassword ? 'text' : 'password'}
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        sx={{ mt: { lg: '40px', md: '32px', xs: '32px' } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            '& svg': {
                                                width: '16px',
                                                height: '16px',
                                            },
                                        }}
                                    >
                                        {state.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        id="outlined-required1"
                        label={t('loginPage:text.confirmPassword')}
                        placeholder={t('loginPage:text.confirmNewPassword')}
                        fullWidth
                        size="small"
                        sx={{ mt: { lg: '40px', md: '32px', xs: '32px' } }}
                        type={state.showRePassword ? 'text' : 'password'}
                        {...getFieldProps('repass')}
                        error={Boolean(touched.repass && errors.repass)}
                        helperText={touched.repass && errors.repass}
                        onChange={formik.handleChange}
                        value={formik.values.repass}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowRePassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            '& svg': {
                                                width: '16px',
                                                height: '16px',
                                            },
                                        }}
                                    >
                                        {state.showRePassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <LoadingButton
                        variant="contained"
                        startIcon={<></>}
                        loading={isSubmitting}
                        loadingPosition="start"
                        sx={{ width: 250, mt: { lg: '40px', md: '32px', xs: '32px' } }}
                        type="submit"
                    >
                        {t('loginPage:button.resetPassword')}
                    </LoadingButton>
                    {/* <Typography variant="body1">Reset Password</Typography> */}
                </Box>
            </Form>
        </FormikProvider>
    ) : (
        <Box>
            <Box
                sx={{
                    flex: 1,
                    height: '80%',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    minWidth: 300,
                    minHeight: 240,
                }}
            >
                <Typography variant="h3" textAlign={'center'} color={'red'}>
                    {state.resStatus}
                </Typography>
            </Box>
            <Stack
                direction={'row'}
                width={'100%'}
                justifyContent={'space-between'}
                padding={{ xs: '0 8px 8px 8px', md: '0 16px 16px 16px', lg: '0 24px 24px 24px' }}
                sx={{ paddingTop: 0 }}
            >
                <Button
                    variant="contained"
                    sx={{ width: '150px', height: language === WebLanguage.en ? '' : '56px' }}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    {t('loginPage:title.login')}
                </Button>
                <Button
                    variant="contained"
                    sx={{ width: '150px' }}
                    onClick={() => {
                        navigate('/forgot');
                    }}
                >
                    {t('loginPage:button.resetPassword')}
                </Button>
            </Stack>
        </Box>
    );
}
