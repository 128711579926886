/* eslint-disable eqeqeq */
import _ from 'lodash';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { permitController } from '../../../../controllers';
import UploadBorderStep from '../components/UploadBorderStep';
import UploadContent from '../components/UploadContent';
import UploadTableConfirm from '../components/UploadTableConfirm';
import * as XLSX from 'xlsx';
import useUpsertPermit from '../../addEditPermit/hooks/useUpsertPermit';
import { convertHour } from '../../../../helpers/ConvertHour';
import { RecurringType } from '../../../../models/permits/PermitRecurring';
import { ExcelRawPermit, RawUploadPermitStatus } from '../../../../models/permits/ExcelRawPermit';
import { Permit, ValidateToType } from '../../../../models/permits/Permit';
import moment from 'moment';
import { PermitFilterContext } from '../../hooks/usePermitsFilter';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ConvertToGMT0Time, FormatVRNString } from '../../../../helpers';
import { GetPermitScopes } from '../../helpers';
import { AuthContext } from '../../../../hooks/useAuth';
import { isBronzeRole } from '../../addEditPermit/components/UpsertPermitItem/helpers';
import { addSeconds, startOfDay } from 'date-fns';

interface State {
    step: number;
    fileName: string;
    listVRNChecking: ExcelRawPermit[];
    progressPercent: number;
    fileUpload: File | null;
    isLoading: boolean;
    isLoadingDownload: boolean;
}

enum PermitType {
    //en
    OneDayPermit = 'onedaypermit',
    Indefinite = 'indefinite',
    Temporary = 'temporary',
    //ge
    TagesGenehmigung = 'tagesgenehmigung',
    Unbefristet = 'unbefristet',
    Vorbergehend = 'vorbergehend',
}

export default function useUploadPermit() {
    const upsertPermitData = useUpsertPermit();
    const { filterState, dataState, isScopeValid, tenantLocationId } = upsertPermitData;
    const { t } = useContext(LanguageContext);
    const { account } = useContext(AuthContext);
    const [state, setState] = useState<State>({
        step: 1,
        fileName: '',
        listVRNChecking: [],
        progressPercent: 0,
        fileUpload: null,
        isLoading: false,
        isLoadingDownload: false,
    });
    const uploadInputRef = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate();

    const setProgress = (percent: number) => {
        setState((p) => ({ ...p, progressPercent: percent }));
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setState((p) => ({ ...p, isLoading: true }));
        setProgress(0);
        if (!e.target.files) {
            return;
        }
        setProgress(10);
        const file = e.target.files[0];
        setState((p) => ({ ...p, fileName: file.name, fileUpload: file }));

        //read data from file
        var reader = new FileReader();
        reader.onload = function (e) {
            let hasErr = false;
            setProgress(30);
            var data = e.target!.result;
            let readerData = XLSX.read(data, { type: 'binary' });
            const wsName = readerData.SheetNames[0];
            const ws = readerData.Sheets[wsName];
            /* Convert array to json*/
            const startRows = 3;
            const dataParse: any[] = XLSX.utils.sheet_to_json(ws, {
                header: 1,
                range: startRows,
                raw: false,
                dateNF: 'DD/MM/yyyy',
            });

            const rawPermitData: ExcelRawPermit[] = [];
            setProgress(40);

            if (dataParse.length > 0) {
                const maxRows = Math.min(400, dataParse.length);
                for (let index = 0; index < maxRows; index++) {
                    const rowData = dataParse[index];
                    if (rowData[1] || rowData[2]) {
                        const vrn = rowData[1] ? FormatVRNString(rowData[1]) : null;
                        const recurringType = rowData[2] ? getRecurType(rowData[2]) : null;
                        let errorMessages = [];
                        if (!vrn) errorMessages.push('VRN is missing or invalid');
                        if (recurringType === null) errorMessages.push('Recurring Type is missing or invalid');

                        if (errorMessages.length > 0) {
                            toast.error(`Error in row ${index + 1 + startRows}: ${errorMessages.join(', ')}.`);
                            hasErr = true;
                            break;
                        }

                        const permitDataItem = {
                            zoneId: filterState.zoneId ?? 0,
                            companyId: filterState.companyId ?? 0,
                            locationId: isBronzeRole(account.label) ? tenantLocationId : filterState.locationId ?? 0,
                            shopId: isBronzeRole(account.label) ? account.id! : filterState.shopId ?? 0,
                            vrn: FormatVRNString(rowData[1]),
                            recurringType: getRecurType(rowData[2]),
                            startDate: rowData[3],
                            endDate: recurringType === RecurringType.temporary ? rowData[4] : null,
                            type: rowData[5],
                            firstName: rowData[6],
                            lastName: rowData[7],
                            startTime: rowData[8] ?? '00:00',
                            endTime: rowData[9] ?? '23:59',
                            bayNumber: rowData[10],
                            comment: rowData[11],
                            requestedBy: rowData[12],
                            tenantId: isBronzeRole(account.label) ? account.id! : filterState.shopId,
                            recurringEvery: 1,
                        };
                        rawPermitData.push(permitDataItem);
                    }
                }
            }

            if (hasErr) {
                handleCancelUpload();
                setState((p) => ({ ...p, isLoading: false }));
            } else {
                setProgress(50);
                // //check
                if (rawPermitData.length > 0) {
                    setProgress(75);
                    permitController
                        .checkVRN(rawPermitData.slice())
                        .then((res) => {
                            setState((p) => ({ ...p, listVRNChecking: res, isLoading: false })); //
                            setProgress(100);
                        })
                        .catch((err) => {
                            if (err.response.status !== 500) toast.error(t('permits:upload.invalidFileFormat'));
                            handleCancelUpload();
                            setState((p) => ({ ...p, isLoading: false }));
                        });
                }
            }
        };
        reader.readAsBinaryString(file);

        //clear input file
        (e.target as HTMLInputElement).value = '';
    };

    const getRecurType = (string: string) => {
        const sanitizedString = string.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() as any;
        if ([PermitType.TagesGenehmigung, PermitType.OneDayPermit].includes(sanitizedString)) {
            return RecurringType.once;
        }
        if ([PermitType.Indefinite, PermitType.Unbefristet].includes(sanitizedString)) {
            return RecurringType.indefinite;
        }
        if ([PermitType.Temporary, PermitType.Vorbergehend].includes(sanitizedString)) {
            return RecurringType.temporary;
        }
        return null;
    };

    const convertValidateToWithToHour = (date: Date, duration: number) => {
        return ConvertToGMT0Time(addSeconds(startOfDay(date), duration ?? 0));
    };

    //clear content when change filter
    useEffect(() => {
        setState({
            step: 1,
            fileName: '',
            listVRNChecking: [],
            progressPercent: 0,
            fileUpload: null,
            isLoading: false,
            isLoadingDownload: false,
        });
    }, [filterState]);

    const handleCancelUpload = () => {
        setState((p) => ({
            ...p,
            fileName: '',
            listVRNChecking: [],
            progressPercent: 0,
            fileUpload: null,
        }));
    };

    const setIsLoadingProgress = (val: boolean) => {
        setState((p) => ({ ...p, isLoadingDownload: val }));
    };

    const handleNextStep = () => {
        if (state.progressPercent === 100) {
            const step = _.cloneDeep(state.step);
            //save permit
            if (step === 2) {
                handleSavePermits();
            } else {
                setState((p) => ({ ...p, step: step + 1 }));
            }
        }
    };

    const goBackOrToPermitsPage = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/permits');
        }
    };

    const handleSavePermits = () => {
        //set loading button
        setState((p) => ({ ...p, isLoading: true }));
        const permits: Permit[] = [];
        //remove duplicate Permit
        const listVRNChecking: ExcelRawPermit[] = Array.from(
            new Set(_.cloneDeep(state.listVRNChecking).map((el) => JSON.stringify(el)))
        ).map((el) => JSON.parse(el));

        //create list permit from valid item
        if (listVRNChecking.length > 0) {
            listVRNChecking.forEach((item) => {
                if (item.status == RawUploadPermitStatus.Valid) {
                    permits.push(handleCreatePermit(item));
                }
            });

            //call api add permit
            setState((p) => ({ ...p, permitReq: permits }));
            if (permits.length > 0) {
                // console.log(permits);
                permitController
                    .Upsert(permits)
                    .then((res) => {
                        //action when add permit done
                        toast.success(t('permits:upload.uploadDone'));
                        //back to permits page
                        goBackOrToPermitsPage();
                        //set loading button
                        setState((p) => ({ ...p, permitRes: res }));
                    })
                    .catch((err) => {
                        console.log('🚀 ~ handleSavePermits ~ err:', err);
                        if (err.response.status !== 500) toast.error(err.response.data.value);
                        //set loading button
                    })
                    .finally(() => {
                        setState((p) => ({ ...p, isLoading: false }));
                    });
            } else {
                toast.error(t('notification:YouHaveReachedTheMaximumNumberOfPermit'));
                setState((p) => ({ ...p, isLoading: false }));
            }
        }
    };

    const handleCreatePermit = (rawPermit: ExcelRawPermit) => {
        const isIndefinite = rawPermit.recurringType == RecurringType.indefinite;
        const isOneDay = rawPermit.recurringType == RecurringType.once;
        const permit: Permit = {
            id: 0,
            firstName: rawPermit.firstName!,
            lastName: rawPermit.lastName!,
            type: rawPermit.type!,
            vrnNumber: FormatVRNString(rawPermit.vrn!),
            validateFrom: ConvertToGMT0Time(
                moment(isIndefinite ? new Date() : rawPermit.startDate!, 'DD/MM/YYYY')
                    .startOf('day')
                    .toDate()
            ),
            validateToType: isIndefinite ? ValidateToType.infinite : ValidateToType.absoluteAt,
            validateTo: isIndefinite
                ? undefined
                : isOneDay
                ? ConvertToGMT0Time(moment(rawPermit.startDate!, 'DD/MM/YYYY').startOf('day').toDate())
                : convertValidateToWithToHour(
                      moment(rawPermit.endDate!, 'DD/MM/YYYY').toDate(),
                      convertHour.getSecondFromHoursString(rawPermit.endTime!)
                  ),
            tenantId: isBronzeRole(account.label) ? account.id : filterState.shopId ?? 0,
            scopes: GetPermitScopes(filterState, false, account, tenantLocationId),
            permitRecurring: [
                {
                    id: 0,
                    fromHour: isIndefinite
                        ? convertHour.getSecondFromStartOfDay(moment().startOf('day').toDate())
                        : convertHour.getSecondFromHoursString(rawPermit.startTime!),
                    toHour: isIndefinite
                        ? convertHour.getSecondFromStartOfDay(moment().endOf('day').toDate())
                        : convertHour.getSecondFromHoursString(rawPermit.endTime!),
                    permitRecurringDays: [
                        {
                            id: 0,
                            firstExecuteAt: ConvertToGMT0Time(
                                moment(isIndefinite ? new Date() : rawPermit.startDate!, 'DD/MM/YYYY')
                                    .startOf('day')
                                    .toDate()
                            ),
                            nextExecuteAt: ConvertToGMT0Time(
                                moment(isIndefinite ? new Date() : rawPermit.startDate!, 'DD/MM/YYYY')
                                    .startOf('day')
                                    .toDate()
                            ),
                        },
                    ],
                    recurringEvery: 1,
                    recurringType: rawPermit.recurringType!,
                },
            ],
            comment: rawPermit.comment,
            bayNumber: rawPermit.bayNumber ? rawPermit.bayNumber.toString() : undefined,
            requestedBy: rawPermit.requestedBy ?? '',
        };
        return permit;
    };

    const getStepComponent = () => {
        switch (state.step) {
            case 1: {
                return (
                    <UploadBorderStep
                        filterData={dataState}
                        filter={filterState}
                        isLoadingDownload={state.isLoadingDownload}
                        setIsLoadingProgress={setIsLoadingProgress}
                        content={
                            <UploadContent
                                progressPercent={state.progressPercent}
                                handleFileUpload={handleFileUpload}
                                uploadInputRef={uploadInputRef}
                                fileName={state.fileName}
                                handleCancelUpload={handleCancelUpload}
                                filter={filterState}
                                file={state.fileUpload}
                                dataState={dataState}
                                isLoading={state.isLoading}
                                isScopeValid={isScopeValid}
                            />
                        }
                    />
                );
            }
            case 2: {
                return <UploadTableConfirm dataState={dataState} listVRNChecking={state.listVRNChecking} />;
            }
            default: {
                return <></>;
            }
        }
    };
    return {
        ...state,
        setState,
        t,
        handleNextStep,
        getStepComponent,
        handleCancelUpload,
        upsertPermitData,
        isScopeValid,
        filterState,
    };
}

export const UploadPermitsContext = React.createContext<ReturnType<typeof useUploadPermit>>({} as any);
