/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import BaseListRender from '../../../components/BaseListRender';
import NoDataToDisplay from '../../../components/NoDataToDisplay';
import IAutoCompleteRF from '../../../components/autoComplete/IAutoComplete2';
import { LanguageContext } from '../../../contexts/useLanguage';
import { companiesController, locationController } from '../../../controllers';
import { CompaniesContext } from '../../../hooks/useCompanies';
import { LoadingFilter, _initLoadingFilter } from '../../../hooks/useFilter';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { FilterRequest } from '../../../models/BaseFilter';
import { Company } from '../../../models/Company';
import { LocationWithDetail } from '../../../models/Location';
import IconSearch from '../components/svg/IconSearch';
import CompanyItem from './components/CompanyItem';
import { useSearchParams } from 'react-router-dom';
import IAutoCompleteLocation from '../../../components/autoComplete/IAutoCompleteLocation';

interface DataCompaniesState {
    locations: LocationWithDetail[];
}

export enum ParamsFilterCompany {
    location = 'locationCId',
    searchC = 'searchCompany',
    pageC = 'pageC',
}

interface FilterStateId {
    locationId?: number;
    search?: string;
    page?: number;
}

export default function CompanyPage() {
    const { t } = useContext(LanguageContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const params = {
        locationId: searchParams.get(ParamsFilterCompany.location),
        search: searchParams.get(ParamsFilterCompany.searchC),
        page: searchParams.get(ParamsFilterCompany.pageC),
    };

    const { companyPaging, setCompanyPaging, pagingLoading, setPagingLoading } = useContext(CompaniesContext);

    const [dataCompaniesState, setDataCompaniesState] = useState<DataCompaniesState>({ locations: [] });
    const setPartialCompaniesData = (partial: Partial<DataCompaniesState>) =>
        setDataCompaniesState((prev) => ({ ...prev, ...partial }));

    const [loading, setLoading] = useState<LoadingFilter>(_initLoadingFilter);
    const setPartialLoading = (partial: Partial<LoadingFilter>) => setLoading((prev) => ({ ...prev, ...partial }));

    const [filterState, setFilterState] = useState<FilterStateId>({
        locationId: !!params.locationId ? Number(params.locationId) : 0,
        search: !!params.search ? params.search : '',
        page: !!params.page ? Number(params.page) : 1,
    });

    const setPartialFilter = (partial: Partial<FilterStateId>) => setFilterState((prev) => ({ ...prev, ...partial }));

    useEffect(() => {
        handleChangeFilterToParams();
    }, [filterState, searchParams]);

    const handleChangeFilterToParams = () => {
        const newParams = new URLSearchParams({});

        if (filterState.locationId) newParams.append(ParamsFilterCompany.location, filterState.locationId.toString());
        if (filterState.search && filterState.search !== '')
            newParams.append(ParamsFilterCompany.searchC, filterState.search ?? '');
        if (filterState.page && filterState.page !== 1)
            newParams.append(ParamsFilterCompany.pageC, filterState.page.toString());

        setSearchParams(newParams);
    };

    useScrollToTop();
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        getMyLocation();
    }, []);

    const getMyLocation = () => {
        setPartialLoading({ isLoadingLocation: true });
        locationController
            .getAllRF({
                pageSize: 10000,
                page: 1,
                filter: { companyId: 0, regionId: 0 },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                setPartialCompaniesData({ locations: res.data });
            })
            .finally(() => setPartialLoading({ isLoadingLocation: false }));
    };

    const onChangeSearch = (e: any) => {
        setPartialFilter({ search: e.target.value, locationId: undefined, page: 1 });
    };

    const onClickClearSearch = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            setPartialFilter({ search: '', locationId: undefined, page: 1 });
        }
    };

    const handleChangeLocation = (location?: LocationWithDetail) => {
        setPartialFilter({ locationId: location?.id ?? undefined, search: '', page: 1 });

        inputRef.current && (inputRef.current.value = '');
    };

    useEffect(() => {
        setPagingLoading(true);
        companiesController
            .filter({
                page: filterState.page,
                pageSize: 6,
                search: {
                    fields: ['displayName'],
                    value: filterState.search ?? '',
                },
                filter: {
                    locationId: filterState.locationId ?? 0,
                },
            } as FilterRequest<Company>)
            .then((res) => {
                setCompanyPaging(res);
            })
            .finally(() => setPagingLoading(false));
    }, [filterState]);

    return (
        <>
            <Box>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant="h3" fontWeight={400}>
                        {t('companies:title.companies')}
                    </Typography>
                    <Button
                        variant="cancelSmall"
                        sx={{ ml: 'px' }}
                        onClick={() => {
                            setFilterState({});
                            inputRef.current && (inputRef.current.value = '');
                        }}
                    >
                        {t('dashboard:button.resetFilter')}
                    </Button>
                </Stack>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                        <TextField
                            inputRef={inputRef}
                            fullWidth
                            placeholder={t('companies:input.searchByName')}
                            onChange={_.debounce((value) => onChangeSearch(value), 1000)}
                            inputProps={{
                                maxLength: 75,
                            }}
                            // sx={{ '& .MuiOutlinedInput-root': { height: '37px' } }}
                            defaultValue={filterState.search}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        sx={{
                                            '& .clear-content:hover': {
                                                cursor: 'pointer',
                                            },
                                        }}
                                    >
                                        <IoCloseOutline
                                            className="clear-content"
                                            fontSize={'20px'}
                                            onClick={onClickClearSearch}
                                            style={{ display: inputRef.current?.value !== '' ? 'block' : 'none' }}
                                        />

                                        <IconButton>
                                            <IconSearch />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <IAutoCompleteLocation
                            listData={dataCompaniesState.locations}
                            itemValue={dataCompaniesState.locations.find((l) => l.id === filterState.locationId)}
                            label={t('dashboard:input.location')}
                            keyLabel={'name'}
                            keyEqual={'id'}
                            setFilter={handleChangeLocation}
                            isHaveAllOptions={true}
                            allOptionLabel="Select location"
                            isLoading={loading.isLoadingLocation}
                            disabled={loading.isLoadingLocation}
                            keyStatus="locationStatus"
                        />
                    </Grid>
                </Grid>
            </Box>

            <BaseListRender
                styleContainer={{ mt: 4 }}
                styleList={{ gap: 2 }}
                noDataComponent={<NoDataToDisplay text={t('companies:text.noDataToDisplay')} />}
                paging={companyPaging}
                renderedItem={(feedBack) => <CompanyItem companyWithDetail={feedBack} />}
                isLoading={pagingLoading}
                setPage={(page) => {
                    setPartialFilter({ page });
                }}
            />
        </>
    );
}
