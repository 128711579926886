import React, { CSSProperties, useContext } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables, ChartOptions, ChartData } from 'chart.js';
import font from '../../config/Fonts';
import color from '../../config/Colors';
import { AnprMatchesDailyStats } from '../../models/AnprMatchesDailyStats';
import { formatDate } from '../../helpers/moment';
import { LanguageContext } from '../../contexts/useLanguage';
import useMedia from '../../hooks/useMedia';
import { COLOR_CAPACITY, COLOR_DAILY_VISITS } from '../../constants';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatNumber } from '../../helpers/FormatCurrency';
import { GrossRevenueByLT } from '../../models/grossRevenue/GrossRevenueByLT';
ChartJS.register(...registerables);

type Props = {
    dailyStats: GrossRevenueByLT[];
};

export default function BarChartGrossRevenueByLTs(props: Props) {
    const { isMobileSM, isMediumMD } = useMedia();
    const { t } = useContext(LanguageContext);

    const data: ChartData<'bar', number[], string> = {
        labels: props.dailyStats && props.dailyStats.map((item) => formatDate.getLocalDay2(item.date)),
        datasets: [
            {
                label: 'Booking amount',
                data: props.dailyStats && props.dailyStats.map((item) => item.total!),
                backgroundColor: COLOR_DAILY_VISITS.ev,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        // set height not crash
        maintainAspectRatio: false,
        plugins: {
            //note
            legend: {
                display: false,
                position: 'bottom' as const,
                align: 'center',
                //set note label font
                labels: {
                    font: {
                        family: font,
                        size: isMobileSM ? 12 : isMediumMD ? 14 : 16,
                    },
                    padding: isMobileSM ? 10 : 20,
                    boxWidth: 5,
                    boxHeight: 5,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },

            // Top Label
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                font: {
                    family: font,
                    size: isMobileSM || props.dailyStats.length > 10 ? 9 : 11,
                },

                formatter(value, context) {
                    const dataSetArr: number[] = [];
                    context.chart.data.datasets.forEach((item) => {
                        if (item.data[context.dataIndex] !== undefined) {
                            dataSetArr.push(Number(item.data[context.dataIndex]));
                        }
                    });
                    let total = 0;
                    if (dataSetArr.length > 0) {
                        total = dataSetArr.reduce((total, item) => (total += item), 0);
                    }
                    if (context.datasetIndex === dataSetArr.length - 1) {
                        return '£' + formatNumber.formatEURO(total);
                    }
                    return '';
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label(tooltipItems: any) {
                        return ` ${tooltipItems.dataset.label}: £${tooltipItems.formattedValue}`;
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        //setting grid
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                },
                stacked: true,
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                    //y step
                    stepSize: 1000,
                },

                //y label start at 0
                beginAtZero: true,
                min: 0,
                stacked: true,
            },
        },
    };

    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
}
