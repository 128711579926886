import { ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { formatDate } from '../../helpers/moment';

type Props<T> = {
    chartData: T[];
    chartColor: string[];
    label: string[];
    dataKeys: (keyof T)[];
    dataKeysDate: keyof T;
};

export default function BarStackedChart<T>({ chartData, chartColor, label, dataKeys, dataKeysDate }: Props<T>) {
    const data: ChartData<'bar'> = {
        labels: chartData.map((item) => formatDate.getLocalDay2(new Date(item[dataKeysDate] as unknown as string))),
        datasets: label.map((label, index) => ({
            label: label,
            data: chartData.map((item) => item[dataKeys[index]] as unknown as number),
            backgroundColor: chartColor[index],
            borderColor: chartColor[index],
            maxBarThickness: 50,
            borderRadius: 3,
        })),
    };

    const maxTotalValue = chartData.reduce((maxTotal, currentItem) => {
        const totalValue = dataKeys.reduce((sum, key) => sum + (currentItem[key] as unknown as number), 0);
        return Math.round(Math.max(maxTotal, totalValue));
    }, 0);

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    title(tooltipItems) {
                        return tooltipItems[0].dataset.label ?? '';
                    },
                    label(tooltipItem) {
                        const itemIndex = tooltipItem.dataIndex;
                        const value = tooltipItem.raw as number;
                        const total = dataKeys.reduce(
                            (sum, key) => sum + (chartData[itemIndex][key] as unknown as number),
                            0
                        );
                        return `${value} (${calculatePercentage(value, total)})`;
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    stepSize: maxTotalValue != 0 ? Math.round(maxTotalValue / 4) : 20,
                    callback(this, tickValue, index, ticks) {
                        return tickValue;
                    },
                },
                max: maxTotalValue != 0 ? undefined : 100,
                beginAtZero: true,
                min: 0,
                stacked: true,
            },
        },
    };

    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
}

const calculatePercentage = (amount: number, total?: number): string => {
    const _per = (amount / (total || 1)) * 100;
    if (_per === 0) return '0%';
    return `${_per.toFixed(1)}%`;
};
