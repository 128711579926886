import { Chip, MenuItem, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import ChipDeleteIcon from '../../../assets/images/ChipDeleteIcon';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { StatusFilter } from '../../../models/permits/FilterPermits';
import { AdvancedName } from '../hooks/usePermitsFilter';
import AdvancedFilter from './AdvancedFilter';

type Props = {
    value: StatusFilter;
    handleRemove: (advancedName: AdvancedName) => void;
    setStatus: (status: StatusFilter) => void;
};

export default function AdvancedStatus(props: Props) {
    const { t } = useContext(LanguageContext);
    const [enumNameStatus, setEnumNameStatus] = useState<StatusFilter>(props.value);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onClickSetName = (item: StatusFilter) => {
        setEnumNameStatus(item);
        setAnchorEl(null);
        props.setStatus(item);
    };

    const getName = (name: StatusFilter) => {
        if (name === StatusFilter.All) {
            return t('permits:text.all');
        }
        if (name === StatusFilter.Active) {
            return t('action:active');
        }
        if (name === StatusFilter.Expired) {
            return t('action:expired');
        }
        if (name === StatusFilter.Pending) {
            return t('action:pending');
        }
        if (name === StatusFilter.Deleted) {
            return "Deleted";
        }
    };

    const arrayNameStatus: StatusFilter[] = [
        StatusFilter.All,
        StatusFilter.Active,
        StatusFilter.Expired,
        StatusFilter.Pending,
        StatusFilter.Deleted,
    ];

    return (
        <AdvancedFilter
            isShowButton={false}
            openElement={
                <Chip
                    label={t('action:status') + ': ' + getName(enumNameStatus)}
                    variant="outlined"
                    sx={[
                        anchorEl !== null
                            ? {
                                  background: 'black',
                                  '& .svg-icon-stroke': {
                                      stroke: 'white',
                                  },
                                  color: 'white',
                              }
                            : {},
                        { '& svg': { mr: '5px' }, minWidth: '140px' },
                    ]}
                    deleteIcon={<ChipDeleteIcon />}
                    onClick={() => {}}
                    onDelete={() => {
                        props.handleRemove('status');
                    }}
                />
            }
            MenuItems={arrayNameStatus.map((item, index) => {
                const isLastBorderNone = index === arrayNameStatus.length - 1;
                return (
                    <MenuItemTenant
                        handleClick={onClickSetName}
                        name={Number(item) as StatusFilter}
                        key={index}
                        getName={getName}
                        isLastBorderNone={isLastBorderNone}
                    />
                );
            })}
            anchorEl={anchorEl}
            setAnchor={setAnchorEl}
        />
    );
}

type PropsChild = {
    name: StatusFilter;
    handleClick: (item: StatusFilter) => void;
    getName: (name: StatusFilter) => string | undefined;
    isLastBorderNone: boolean;
};

function MenuItemTenant(props: PropsChild) {
    return (
        <MenuItem
            // disableRipple={true}
            sx={{
                padding: '5px 5px',
                borderBottom: `${props.isLastBorderNone ? '' : `1px solid ${color.grey200}`} `,
                borderRadius: '5px',
                '&:hover': {
                    background: color.primary,
                },
                '& .MuiTouchRipple-child': {
                    backgroundColor: color.primary,
                },
                flexDirection: 'column',
            }}
            onClick={() => props.handleClick(props.name)}
        >
            <Typography variant="body1" component={'h6'} width={'100%'} textTransform="capitalize">
                {props.getName(props.name)}
            </Typography>
        </MenuItem>
    );
}
