import moment from 'moment';
import * as momentTz from 'moment-timezone';

class FormatDate {
    getLocalDate(date: any) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }
    getLocalHour(date: any) {
        return moment(date).format('HH:mm:ss');
    }
    getLocalDay(date: any) {
        return moment(date).format('DD/MM/YYYY');
    }
    getLocalDay2(date: any) {
        return moment(date).format('DD/MM');
    }
    getLocalDay3(date: any) {
        return moment(date).format('DD');
    }

    convertStellaToGMT(date: Date) {
        if (date === null) return date;
        const currentLondonGMT = momentTz.tz('Europe/London').utcOffset() / 60;
        return moment(date).add(currentLondonGMT, 'h').format('DD/MM/YYYY HH:mm');
    }
}

export const formatDate = new FormatDate();
