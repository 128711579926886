import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { permitController } from '../../../../controllers';
import { BlobFile } from '../../../../helpers/BlobFileHepler';
import { DataUpsertPermit, UpsertFilter } from '../../addEditPermit/hooks/useUpsertPermit';
import { GridUploadBorder } from './UploadBase';
import { UploadPermitsContext } from '../hooks/useUploadPermits';
import { WebLanguage } from '../../../../constants';

type Props = {
    content?: JSX.Element;
    filterData: DataUpsertPermit;
    filter: UpsertFilter;
    isLoadingDownload: boolean;
    setIsLoadingProgress: (val: boolean) => void;
};

interface ExcelPaths {
    [role: string]: string;
}

export default function UploadBorderStep({
    content,
    filterData,
    filter,
    isLoadingDownload,
    setIsLoadingProgress,
}: Props) {
    const { t, default: language } = useContext(LanguageContext);
    let excelPaths: ExcelPaths = {
        [WebLanguage.en]: 'en/SampleSheet',
        [WebLanguage.de]: 'de/Beispiel',
    };

    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '24px 0px',
            }}
        >
            <GridUploadBorder container mt={1}>
                {content}
            </GridUploadBorder>
            <Typography variant="body2" component="div" padding={'0 15px'} textAlign="center" mt={2}>
                <span style={{ fontWeight: 500 }}>{t('permits:upload.note')}: </span>
                <span>{t('permits:upload.dataMustBeInSameFormatAsPerTheSample')} </span>
                <Typography component="span" variant="body2" sx={{ color: color.priText }}>
                    {t('permits:upload.downloadSampleFormat')}:{' '}
                </Typography>
                <a href={`/assets/upload-template/${excelPaths[language]}.xlsx`} download>
                    <Typography
                        component="span"
                        variant="body2"
                        sx={{
                            color: color.priText,
                            textDecoration: 'underline',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        // onClick={handleDownloadExample}
                    >
                        {t('permits:upload.sampleSheet')}.{' '}
                        {isLoadingDownload ? (
                            <CircularProgress size={8} sx={{ margin: 'auto', color: color.priText }} />
                        ) : (
                            <></>
                        )}
                    </Typography>
                </a>
            </Typography>
        </Grid>
    );
}
