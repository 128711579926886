import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { formatDate } from '../../../helpers/moment';
import { DailyVisitsStatistic } from '../../../models/DailyVisitsStatistic';

type Props = {
    dailyVisitsStatistic: DailyVisitsStatistic;
};

export default function TableRowsDailyVisits(props: Props) {
    return (
        <TableBody>
            {props.dailyVisitsStatistic.dailyData &&
                props.dailyVisitsStatistic.dailyData.map((row, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell key={index} align="center" component="th" scope="row">
                                {formatDate.getLocalDay(row.inDate)}
                            </TableCell>
                            <TableCell align="center">{formatNumber.format(row.qtyTotalOfDiesel!)}</TableCell>
                            <TableCell align="center">{formatNumber.format(row.qtyTotalOfPetrol!)}</TableCell>
                            <TableCell align="center">{formatNumber.format(row.qtyTotalOfEV!)}</TableCell>
                            <TableCell align="center">{formatNumber.format(row.qtyTotalOfHybrid!)}</TableCell>
                            <TableCell align="center">{formatNumber.format(row.qtyTotalOfOther!)}</TableCell>
                            <TableCell align="center">{formatNumber.format(row.visitCount)}</TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
