import { Box, Stack, Typography } from '@mui/material';
import { FAQs } from '../components/BaseFAQ';
export type SectionFAQ = {
    subHeading: string;
    content: FAQs[];
};
export const tenantSection: SectionFAQ[] = [
    {
        subHeading: 'Overview',
        content: [
            {
                question: 'What features do I have access to?',
                answer: (
                    <Box pl={4.5}>
                        <Typography>
                            You will have access to the following features which are listed on the left-hand side of the
                            page:
                        </Typography>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Permits</span> – This allows you to add permits
                                    with all the new functionalities that we have introduced.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Support</span> – This gives you access to your
                                    account managers on the site that can support you throughout your time with us.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Account</span> - This area allows you to update or
                                    change your password and account details if needed.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>FAQs</span> - You will have all frequently asked
                                    questions and the option to download the user manual here.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Permits',
        content: [
            {
                question: 'What are the different recurring types?',
                answer: (
                    <Box pl={1.5}>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>One day permit </span> – schedule permits for one
                                    day only, permits can be scheduled for a specific time period or all day.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Dynamic</span> – schedule permits for daily,
                                    weekly, monthly, permits can be scheduled for a specific time period or all day.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Temporary</span> - the schedule allows for events
                                    to recur, with a maximum duration of 3 months at all times.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Indefinite</span> - schedule permits to recur
                                    indefinitely at all times.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                question: 'How do I maximise my permit allocation?',
                answer: (
                    <Stack spacing={1} pl={4.5}>
                        <Typography>
                            You can maximise your permit allocation with our permit feature. For example, if you have
                            ten (10) maximum permits allocated, this means you can allocate permits to ten (10) unique
                            VRNs. Allocating multiple permits to one (1) VRN will only count as one (1) issued permit.
                        </Typography>
                        <Typography>
                            However, if a permit has been issued on a “One day” recurring type, this won’t use up your
                            permit allocation.
                        </Typography>
                        <Typography>
                            Adding a permit on an “Indefinite” recurring permit type will permanently use up your permit
                            allocation.
                        </Typography>
                        <Typography>
                            Please note that it is advisable to only use the ‘Indefinite’ recurring type, when
                            necessary, as this will permanently use up the permit allocation available to you. If you
                            have permanent staff, then this would be the best use. However, if you have staff working on
                            different shift patterns, try and utilise the weekly and timed based permits for maximum
                            efficiency.
                        </Typography>
                    </Stack>
                ),
            },
            {
                question: 'How do I add a permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can create a permit with the information shown below by clicking into the ‘Permits’ tab
                            and ‘Add permits’:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-3.1.png" alt="Permit 3.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            Once you have selected the above, the page below will then show you what details you need to
                            fill in to add a permit:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-3.2.png" alt="Permit 3.2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I duplicate a permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can duplicate a permit by clicking onto the duplicate icon below: (The fields in your
                            duplicated permit will auto-populate and match the details from your original permit as seen
                            below).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-4.png" alt="Permit 4" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add a one-day permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the ‘Recurring type’ dropdown list and select ‘One day permit’
                            below: (Here, you can allocate a 1-day permit on a specific day between a time/date range).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-5.png" alt="Permit 5" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add dynamic permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Dynamic’. Here, you
                            have the option to select onto ‘Daily’, ‘Weekly’ or ‘Monthly’ permits.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Daily</span> – you can schedule the permit to recur every
                            ‘X’ number of days.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Weekly</span> - you can select onto the permit to recur on
                            specific days of the week.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Monthly</span> - you can select the permit to recur on a
                            particular date in the month.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-6.png" alt="Permit 6" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add temporary permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Temporary’. Please
                            ensure you select a ‘Start date’ and ‘End date’. Please note, you can select up to one month
                            for a temporary permit.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-7.png" alt="Permit 7" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add indefinite permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Indefinite’.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-8.png" alt="Permit 8" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I bulk upload permits? ',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can bulk upload permits by clicking onto ‘Upload permits’ below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-10.1.png" alt="Permit 10.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you are able to upload your file. You can
                            download the ‘Sample sheet’ for your permit uploads in an excel spreadsheet shown below:
                            (Please note, this only works for indefinite permits or one day permits only).{' '}
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-10.2.png" alt="Permit 10.2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Why is my permit not saving?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to ensure that you have selected onto all required fields that are mandatory
                            before saving your permits, otherwise the system will not allow you to add a permit. Your
                            permit should show as ‘(Completed)’ below before being able to save:
                        </Typography>
                        <Typography pl={4.5} mt={2}>
                            Completed permit:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-11.1.png" alt="Permit 11.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            Not completed permit:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-11.2.png" alt="Permit 11.2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I add filters on the permit page to narrow down specific permits I need to view?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can add filters onto the permit page by clicking onto the ‘+’ button below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-12.png" alt="Permit 12" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I search by VRN?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can for specific VRNs by typing the VRN in the ‘Search by VRN’ box below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-13.png" alt="Permit 13" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I delete permits that have already been saved?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can do this by selecting onto the tick box next to the VRN name and select ‘Delete’
                            below: (You can also select onto the ‘Select all’ box to delete more than one permit in the
                            list).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-14.png" alt="Permit 14" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I edit my current permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can edit your current permits by clicking onto the ‘Edit’ icon below: (Please note,
                            you cannot edit all permit information such as the ‘Recurring type’ and ‘Start date’).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-15.png" alt="Permit 15" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I reset my current filters? ',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can reset your filters by clicking onto the ‘Reset filter’ button below: (This will
                            remove any filters that you currently have on the page).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-16.png" alt="Permit 16" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Why can I not edit the bay information when adding a permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            This is predefined by your account manager, managing agent or client. Therefore, you are
                            unable to edit this section unless permitted. You can discuss this with them directly if you
                            wish to manage your bay allocation.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-17.png" alt="Permit 17" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Support',
        content: [
            {
                question: 'How do I contact my account manager?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click onto the ‘Support’ tab and then you will be directed to the page
                            below: (Here, you will need to select onto the dropdown menu ‘Location’ and ‘Company’ and
                            select the ‘ready to send’ button underneath the required account manager. Please note, the
                            ‘Subject’ and ‘Message’ field is a mandatory requirement).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/support-1.png" alt="Support 1" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I report a bug?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click onto the 'Report a bug' tab below. Here, you can provide a
                            description of your bug and upload any supporting screenshots so that we can help you to
                            best of our ability. Please note, our support email address will automatically appear when
                            you click onto this tab.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/support-2.png" alt="Support 2" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Account',
        content: [
            {
                question: 'How do I change my password?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can change your password by clicking into the ‘Account’ tab and clicking onto ‘Reset
                            password’ below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-1.1.png" alt="Account 1.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            The below pop-up will appear for you to update your password:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-1.2.png" alt="Account 1.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I change my phone number?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can change your phone number by clicking into the ‘Account’ tab and clicking onto
                            ‘Update account’ below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-2.1.png" alt="Account 2.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            The below pop-up will appear for you to update your account details:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-2.2.png" alt="Account 2.2" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Accessing iHub platform from your mobile device',
        content: [
            {
                question: 'What is the URL link for logging into iHub on my mobile device?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Type in the iHub URL below into your mobile device’s browser - URL:{' '}
                            <Typography component="span" color="secondary">
                                <a href="https://ihub-insights-prod-ukpc.azurewebsites.net" target="blank">
                                    https://ihub-insights-prod-ukpc.azurewebsites.net
                                </a>
                            </Typography>
                        </Typography>
                    </Box>
                ),
            },
            {
                question: 'Can both iOS users and Android users access the platform from our mobile devices?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, both iOS users and Android users can access the platform. Please refer to the user
                            manual for further details on how to download the platform onto your device.
                        </Typography>
                    </Box>
                ),
            },
        ],
    },
];
export const accountManagerSection: SectionFAQ[] = [
    {
        subHeading: 'Overview',
        content: [
            {
                question: 'What features do I have access to?',
                answer: (
                    <Box pl={4.5}>
                        <Typography>
                            You will have access to the following features which are listed on the left-hand side of the
                            page:
                        </Typography>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Dashboard</span> – This allows you to get granular
                                    insights into any location.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Insights</span> – This allows you to access the
                                    particular report within their filtering selection.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Companies</span> - Allows you to manage your
                                    locations within your company or companies.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Permits</span> - This allows you to add permits
                                    with all the new functionalities that we have introduced.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Support</span> - This gives you access to view
                                    your support account.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Account</span> - This area allows you to update or
                                    change your password and account details if needed.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>FAQs</span> - You will have all frequently asked
                                    questions and the option to download the user manual here.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Tenant list</span> - This area allows you to
                                    access all tenant information across all locations and zones.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                question: 'Can I add filters on the dashboard page?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can add filters onto the page by clicking on any of the filtering fields available
                            as shown below:
                        </Typography>
                        <img src="/assets/images/faqs/account/overview-2.png" alt="Overview 2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I reset my filters?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can reset your filters by clicking into the “Reset filter” button below which will
                            remove any filters you currently have on the page:
                        </Typography>
                        <img src="/assets/images/faqs/account/overview-3.png" alt="Overview 3" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Insights',
        content: [
            {
                question: 'How do I access a particular report?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will be able to access particular reports within the page below by clicking into “View
                            more”:
                        </Typography>
                        <img src="/assets/images/faqs/account/insight-1.png" alt="Insight 1" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I download my reports?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can download your reports into an excel or PDF file by clicking into the “Export”
                            button below:
                        </Typography>
                        <img src="/assets/images/faqs/account/insight-2.png" alt="Insight 2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Where can I see granular details of my graph/chart view?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into “Details” below which will provide you with the granular
                            information of your selected graph:
                        </Typography>
                        <img src="/assets/images/faqs/account/insight-3.png" alt="Insight 3" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Companies',
        content: [
            {
                question: 'Where can I manage my clients’ estate?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can manage your clients estate by searching for the company or selecting into the
                            “select location” dropdown list below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-1.png" alt="Companies 1" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add employees for my clients?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can add employees for your clients by clicking into the employees icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-2.1.png" alt="Companies 2.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you can add employees by clicking onto the
                            “Add employee” button:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-2.2.png" alt="Companies 2.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I edit employee details?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can edit employee details by clicking onto the “Edit” icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-3.png" alt="Companies 3" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How can I get access to all the locations in a company?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click onto the “Locations” icon below where you will see all the locations
                            within a company:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-4.1.png" alt="Companies 4.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the following page below where you can select onto the dropdown
                            list and select “Map view”:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-4.2.png" alt="Companies 4.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I view general information of a location?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can view general information of a location such as the address by clicking into the
                            “List view” dropdown and selecting onto the “General information” icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-5.1.png" alt="Companies 5.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you can also view the pin drop on a map
                            view, alongside the general information:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-5.2.png" alt="Companies 5.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I edit the permit allocation at zone level?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            When a tenant is added, you will select the zones for the maximum permit allocation that the
                            tenant is allowed. To edit your maximum permit allocation at zone level, you will need to
                            click onto the “Zones” icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-6.1.png" alt="Companies 6.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you will need to click onto the “Edit”
                            icon: (Here, you will see a confirmation of your allocated permits)
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-6.2.png" alt="Companies 6.2 " width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be able to edit your allocated permits by amending the details in the “Max
                            permits” box below: (Please note, the tenant will issue the permits, however the maximum
                            number of permits can only be set by you or the managing agent for governance).
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-6.3.png" alt="Companies 6.3 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add tenants?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>You can add tenants by clicking into the “Tenant” icon below:</Typography>
                        <img src="/assets/images/faqs/account/companies-7.1.png" alt="Companies 7.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you will need to click into the “Add
                            tenant” below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-7.2.png" alt="Companies 7.2 " width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you will need to fill out the required
                            fields:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-7.3.png" alt="Companies 7.3 " width="100%" />
                        <Typography pl={4.5} mt={2}>
                            Please note, the “Save only” feature only saves the tenant details on the system. This is
                            useful when you need to create a tenant for a location/zone that is not live yet. The “Save
                            and send” feature will send a confirmation email to the tenant to prompt them to setup a
                            password to gain access to the iHub platform. You can also set the maximum number of permits
                            for each zone within the location here.
                        </Typography>
                    </Box>
                ),
            },
            {
                question: 'Can I delete tenants?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can delete tenants by clicking onto the “Delete” icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-8.png" alt="Companies 8" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I prompt the tenant to set-up their own password?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can prompt the tenant to set-up their own password by clicking into the “Send
                            e-mail” icon below. If the confirmation e-mail has been previously sent to the tenant, then
                            the button will read “Re-send e-mail”. However, if a confirmation e-mail has never been sent
                            to the tenant, then the button will read “Send e-mail”.
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-9.1.png" alt="Companies 9.1" width="100%" />
                        <img
                            src="/assets/images/faqs/account/companies-9.2.png"
                            alt="Companies 9.2"
                            width="100%"
                            style={{ marginTop: 16 }}
                        />
                    </Box>
                ),
            },
            {
                question: 'How do I select onto a different range of permits when editing a tenant?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            First, you will need to click onto the companies section on the left hand pane, search for a
                            location or company. Once you have selected onto this, a list will be populated of the
                            different locations and zones. You will then need to click onto the tenants tab and select
                            the edit button.
                        </Typography>
                        <Typography pl={4.5}>
                            Here, you can select onto multiple permits and add the number of permits for your tenant
                            below:
                        </Typography>
                        <img src="/assets/images/faqs/agent/companies-10.png" alt="Companies 10" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I assign zones within the tenant section?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can assign zones within the tenant section, by selecting onto the zones below. You also
                            have the option to assign “All zones” within this section.
                        </Typography>
                        <Typography pl={4.5}>
                            Please note, the permits that you have allocated will only be available in the zones
                            selected below.
                        </Typography>
                        <img src="/assets/images/faqs/agent/companies-11.png" alt="Companies 11" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I edit bay information within the tenant section?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can predefine the bay information for the tenant by including this information in the
                            “Bay information” box below or you have the option to allow the tenant to edit the bay
                            information by clicking onto the tick box below.
                        </Typography>
                        <Typography pl={4.5}>
                            Please note, the motorist can only park in the bays that have been predefined. For example,
                            Bay 1, 2, 3, 4. The tenant cannot edit the bay information once this has been predefined.
                        </Typography>
                        <img src="/assets/images/faqs/agent/companies-12.png" alt="Companies 12" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I enable a lock screen pin for iPark?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            First, you will need to click onto the companies section on the left hand pane, search for a
                            location or company. Once you have selected onto this, a list will be populated of the
                            different locations and zones. You will then need to click onto the tenants tab and select
                            the edit button. You will need to scroll down to the bottom of the page, where you will see
                            the lock screen pin information.
                        </Typography>
                        <Typography pl={4.5}>
                            You will need to click onto the “Enable” button and ensure that you have typed in a pin.
                            Please note, the pin should be 6 digits long and this pin is to be used for anyone managing
                            the iPark tablet.
                        </Typography>
                        <img src="/assets/images/faqs/agent/iPark-1.png" alt="iPark 1" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Permits',
        content: [
            {
                question: 'What are the different recurring types?',
                answer: (
                    <Box pl={1.5}>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>One day permit </span> – Schedule permits for one
                                    day only, permits can be scheduled for a specific time period or all day.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Dynamic</span> – Schedule permits for daily,
                                    weekly, monthly, permits can be scheduled for a specific time period or all day.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Temporary</span> - The schedule allows for events
                                    to recur, with a maximum duration of 3 months at all times.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Indefinite</span> - Schedule permits to recur
                                    indefinitely at all times.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                question: 'Do I have visibility of all the permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            {`Yes, you will have visibility to all the permits that exist on the system. The filtering option allows  you  to  drill  down  from  Company  >  Region  >  Location  >  Zone  >  Tenant.  You  also have the option to search by VRN below:`}
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-2.png" alt="Permits 2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I maximise my permit allocation?',
                answer: (
                    <Stack spacing={1} pl={4.5}>
                        <Typography>
                            You can maximise your permit allocation with our permit feature. For example, if you have
                            ten (10) maximum permits allocated, this means you can allocate permits to ten (10) unique
                            VRNs. Allocating multiple permits to one (1) VRN will only count as one (1) issued permit.
                        </Typography>
                        <Typography>
                            However, if a permit has been issued on a “One day” recurring type, this won’t use up your
                            permit allocation. Adding a permit on an “Indefinite” recurring permit type will permanently
                            use up your permit allocation.
                        </Typography>
                        <Typography>
                            Please note that it is advisable to only use the ‘Indefinite’ recurring type, when
                            necessary, as this will permanently use up the permit allocation available to you. If you
                            have permanent staff, then this would be the best use. However, if you have staff working on
                            different shift patterns, try and utilise the weekly and timed based permits for maximum
                            efficiency.
                        </Typography>
                    </Stack>
                ),
            },
            {
                question: 'How do I add a permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can create a permit with the information shown below by clicking into the ‘Permits’ tab
                            and ‘Add permits’:
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-4.1.png" alt="Permit 4.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            Once you have selected the above, the page below will then show you what details you need to
                            fill in to add a permit:
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-4.2.png" alt="Permit 4.2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I duplicate a permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can duplicate a permit by clicking onto the duplicate icon below: (The fields in your
                            duplicated permit will auto-populate and match the details from your original permit as seen
                            below).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-4.png" alt="Permit 4" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add a one-day permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the ‘Recurring type’ dropdown list and select ‘One day permit’
                            below: (Here, you can allocate a 1-day permit on a specific day between a time/date range).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-5.png" alt="Permit 5" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add dynamic permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Dynamic’. Here, you
                            have the option to select onto ‘Daily’, ‘Weekly’ or ‘Monthly’ permits.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Daily</span> – you can schedule the permit to recur every
                            ‘X’ number of days.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Weekly</span> - you can select onto the permit to recur on
                            specific days of the week.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Monthly</span> - you can select the permit to recur on a
                            particular date in the month.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-6.png" alt="Permit 6" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add temporary permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Temporary’. Please
                            ensure you select a ‘Start date’ and ‘End date’.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-7.png" alt="Permit 7" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add indefinite permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Indefinite’.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-9.png" alt="Permit 9" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I bulk upload permits? ',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can bulk upload permits by clicking onto ‘Upload permits’ below:
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-11.1.png" alt="Permit 11.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you are able to upload your file. You can
                            download the ‘Sample sheet’ for your permit uploads in an excel spreadsheet shown below:
                            (Please note, this only works for indefinite permits or one day permits only).{' '}
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-11.2.png" alt="Permit 11.2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Why is my permit not saving?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to ensure that you have selected onto all required fields that are mandatory
                            before saving your permits, otherwise the system will not allow you to add a permit. Your
                            permit should show as ‘(Completed)’ below before being able to save:
                        </Typography>
                        <Typography pl={4.5} mt={2}>
                            Completed permit:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-11.1.png" alt="Permit 11.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            Not completed permit:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-11.2.png" alt="Permit 11.2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I add filters on the permit page to narrow down specific permits I need to view?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can add filters onto the permit page by clicking onto the ‘+’ button below:
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-13.png" alt="Permit 13" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I search by VRN?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can for specific VRNs by typing the VRN in the ‘Search by VRN’ box below:
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-14.png" alt="Permit 14" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I delete permits that have already been saved?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can do this by selecting onto the tick box next to the VRN name and select ‘Delete’
                            below: (You can also select onto the ‘Select all’ box to delete more than one permit in the
                            list).
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-15.png" alt="Permit 14" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I edit my current permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can edit your current permits by clicking onto the ‘Edit’ icon below: (Please note,
                            you cannot edit all permit information such as the ‘Recurring type’ and ‘Start date’).
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-16.png" alt="Permit 16" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I reset my current filters? ',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can reset your filters by clicking onto the ‘Reset filter’ button below: (This will
                            remove any filters that you currently have on the page).
                        </Typography>
                        <img src="/assets/images/faqs/account/permits-17.png" alt="Permit 17" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Tenant List',
        content: [
            {
                question: 'Where can I access all the tenant list information across all locations and zones?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to select onto the tenant list icon on the left hand side of the screen. Once
                            you have selected onto this, you will be able to filter by location and zone.
                        </Typography>
                        <Typography pl={4.5}>
                            Here, you can find their contact information and also their allocated permits. You also have
                            the option to export all data by clicking onto the ‘Export’ button below.
                        </Typography>
                        <img src="/assets/images/faqs/agent/tenant-list-1.png" alt="Tenant list 1" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Support',
        content: [
            {
                question: 'How do I contact my account manager?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click onto the ‘Support’ tab and then you will be directed to the page
                            below: (Here, you will need to select onto the dropdown menu ‘Location’ and ‘Company’ and
                            select the ‘ready to send’ button underneath the required account manager. Please note, the
                            ‘Subject’ and ‘Message’ field is a mandatory requirement).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/support-1.png" alt="Support 1" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I report a bug?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click onto the 'Report a bug' tab below. Here, you can provide a
                            description of your bug and upload any supporting screenshots so that we can help you to
                            best of our ability. Please note, our support email address will automatically appear when
                            you click onto this tab.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/support-2.png" alt="Support 2" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Account',
        content: [
            {
                question: 'How do I change my password?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can change your password by clicking into the ‘Account’ tab and clicking onto ‘Reset
                            password’ below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-1.1.png" alt="Account 1.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            The below pop-up will appear for you to update your password:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-1.2.png" alt="Account 1.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I change my phone number?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can change your phone number by clicking into the ‘Account’ tab and clicking onto
                            ‘Update account’ below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-2.1.png" alt="Account 2.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            The below pop-up will appear for you to update your account details:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-2.2.png" alt="Account 2.2" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Accessing iHub platform from your mobile device',
        content: [
            {
                question: 'What is the URL link for logging into iHub on my mobile device?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Type in the iHub URL below into your mobile device’s browser - URL:{' '}
                            <Typography component="span" color="secondary">
                                <a href="https://ihub-insights-prod-ukpc.azurewebsites.net" target="blank">
                                    https://ihub-insights-prod-ukpc.azurewebsites.net
                                </a>
                            </Typography>
                        </Typography>
                    </Box>
                ),
            },
            {
                question: 'Can both iOS users and Android users access the platform from our mobile devices?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, both iOS users and Android users can access the platform. Please refer to the user
                            manual for further details on how to download the platform onto your device.
                        </Typography>
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Adding a user to iHub via Stella',
        content: [
            {
                question: 'Do  I  need  to  use  Stella  to  add  a  client  or  user  to  iHub?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you need to use Stella as this is the hub for all of the different products. You can
                            also use this area to search and edit existing users.
                        </Typography>
                    </Box>
                ),
            },
            {
                question: 'What are the different types of user roles?',
                answer: (
                    <Box pl={1.5}>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Account manager</span> – This role has the ability
                                    to see all locations and companies on iHub. This is to be utilised by anyone from
                                    the account management team, or anyone within the UKPC internal organisation.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Managing agent</span> – This is for clients that
                                    need to look at multiple or all locations across multiple companies.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Client</span> - This is for clients that will only
                                    see <span style={{ fontWeight: 500 }}>1x company</span> and selected or all
                                    locations within the company.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                question: 'Where can I find the instructions to create a new user on Stella?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Please refer to the user manual for further details on how to create a new user on Stella.
                        </Typography>
                    </Box>
                ),
            },
        ],
    },
];
export const agentWithClientSection: SectionFAQ[] = [
    {
        subHeading: 'Overview',
        content: [
            {
                question: 'What features do I have access to?',
                answer: (
                    <Box pl={4.5}>
                        <Typography>
                            You will have access to the following features which are listed on the left-hand side of the
                            page:
                        </Typography>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Dashboard</span> – This allows you to get granular
                                    insights into any location.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Insights</span> – This allows you to access the
                                    particular report within their filtering selection.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Companies</span> - Allows you to manage your
                                    locations within your company or companies.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Permits</span> - This allows you to add permits
                                    with all the new functionalities that we have introduced.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Support</span> - This gives you access to your
                                    account managers on the site that can support you throughout your time with us.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Account</span> - This area allows you to update or
                                    change your password and account details if needed.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>FAQs</span> - You will have all frequently asked
                                    questions and the option to download the user manual here.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Tenant list</span> - This area allows you to
                                    access all tenant information across all locations and zones.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                question: 'Can I add filters on the dashboard page?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can add filters onto the page by clicking on any of the filtering fields available
                            as shown below:
                        </Typography>
                        <img src="/assets/images/faqs/account/overview-2.png" alt="Overview 2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I reset my filters?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can reset your filters by clicking into the “Reset filter” button below which will
                            remove any filters you currently have on the page:
                        </Typography>
                        <img src="/assets/images/faqs/account/overview-3.png" alt="Overview 3" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Insights',
        content: [
            {
                question: 'How do I access a particular report?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will be able to access particular reports within the page below by clicking into “View
                            more”:
                        </Typography>
                        <img src="/assets/images/faqs/account/insight-1.png" alt="Insight 1" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I download my reports?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can download your reports into an excel or PDF file by clicking into the “Export”
                            button below:
                        </Typography>
                        <img src="/assets/images/faqs/account/insight-2.png" alt="Insight 2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Where can I see granular details of my graph/chart view?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into “Details” below which will provide you with the granular
                            information of your selected graph:
                        </Typography>
                        <img src="/assets/images/faqs/account/insight-3.png" alt="Insight 3" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Companies',
        content: [
            {
                question: 'Can I quick search by company?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can search specific companies by typing into the “Search by name” box below:
                        </Typography>
                        <img src="/assets/images/faqs/agent/companies-1.png" alt="Companies 1" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add employees?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can add employees by clicking into the employees icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-2.1.png" alt="Companies 2.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you can add employees by clicking onto the
                            “Add employee” button:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-2.2.png" alt="Companies 2.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I edit employee details?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can edit employee details by clicking onto the “Edit” icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-3.png" alt="Companies 3" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How can I get access to all the locations in a company?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click onto the “Locations” icon below where you will see all the locations
                            within a company:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-4.1.png" alt="Companies 4.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the following page below where you can select onto the dropdown
                            list and select “Map view”:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-4.2.png" alt="Companies 4.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I view general information of a location?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can view general information of a location such as the address by clicking into the
                            “List view” dropdown and selecting onto the “General information” icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-5.1.png" alt="Companies 5.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you can also view the pin drop on a map
                            view, alongside the general information:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-5.2.png" alt="Companies 5.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I edit the permit allocation at zone level?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            When a tenant is added, you will select the zones for the maximum permit allocation that the
                            tenant is allowed. To edit your maximum permit allocation at zone level, you will need to
                            click onto the “Zones” icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-6.1.png" alt="Companies 6.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you will need to click onto the “Edit”
                            icon: (Here, you will see a confirmation of your allocated permits)
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-6.2.png" alt="Companies 6.2 " width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be able to edit your allocated permits by amending the details in the “Max
                            permits” box below: (Please note, the tenant will issue the permits, however the maximum
                            number of permits can only be set by you or the managing agent for governance).
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-6.3.png" alt="Companies 6.3 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add tenants?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>You can add tenants by clicking into the “Tenant” icon below:</Typography>
                        <img src="/assets/images/faqs/account/companies-7.1.png" alt="Companies 7.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you will need to click into the “Add
                            tenant” below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-7.2.png" alt="Companies 7.2 " width="100%" />
                        <Typography pl={4.5} mt={2}>
                            You will then be directed to the page below where you will need to fill out the required
                            fields:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-7.3.png" alt="Companies 7.3 " width="100%" />
                        <Typography pl={4.5} mt={2}>
                            Please note, the “Save only” feature only saves the tenant details on the system. This is
                            useful when you need to create a tenant for a location/zone that is not live yet. The “Save
                            and send” feature will send a confirmation email to the tenant to prompt them to setup a
                            password to gain access to the iHub platform. You can also set the maximum number of permits
                            for each zone within the location here.
                        </Typography>
                    </Box>
                ),
            },
            {
                question: 'Can I delete tenants?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can delete tenants by clicking onto the “Delete” icon below:
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-8.png" alt="Companies 8" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I prompt the tenant to set-up their own password?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can prompt the tenant to set-up their own password by clicking into the “Send
                            e-mail” icon below. If the confirmation e-mail has been previously sent to the tenant, then
                            the button will read “Re-send e-mail”. However, if a confirmation e-mail has never been sent
                            to the tenant, then the button will read “Send e-mail”.
                        </Typography>
                        <img src="/assets/images/faqs/account/companies-9.1.png" alt="Companies 9.1" width="100%" />
                        <img
                            src="/assets/images/faqs/account/companies-9.2.png"
                            alt="Companies 9.2"
                            width="100%"
                            style={{ marginTop: 16 }}
                        />
                    </Box>
                ),
            },
            {
                question: 'How do I select onto a different range of permits when editing a tenant?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            First, you will need to click onto the companies section on the left hand pane, search for a
                            location or company. Once you have selected onto this, a list will be populated of the
                            different locations and zones. You will then need to click onto the tenants tab and select
                            the edit button.
                        </Typography>
                        <Typography pl={4.5}>
                            Here, you can select onto multiple permits and add the number of permits for your tenant
                            below:
                        </Typography>
                        <img src="/assets/images/faqs/agent/companies-10.png" alt="Companies 10" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I assign zones within the tenant section?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can assign zones within the tenant section, by selecting onto the zones below. You also
                            have the option to assign “All zones” within this section.
                        </Typography>
                        <Typography pl={4.5}>
                            Please note, the permits that you have allocated will only be available in the zones
                            selected below.
                        </Typography>
                        <img src="/assets/images/faqs/agent/companies-11.png" alt="Companies 11" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I edit bay information within the tenant section?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can predefine the bay information for the tenant by including this information in the
                            “Bay information” box below or you have the option to allow the tenant to edit the bay
                            information by clicking onto the tick box below.
                        </Typography>
                        <Typography pl={4.5}>
                            Please note, the motorist can only park in the bays that have been predefined. For example,
                            Bay 1, 2, 3, 4. The tenant cannot edit the bay information once this has been predefined.
                        </Typography>
                        <img src="/assets/images/faqs/agent/companies-12.png" alt="Companies 12" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I enable a lock screen pin for iPark?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            First, you will need to click onto the companies section on the left hand pane, search for a
                            location or company. Once you have selected onto this, a list will be populated of the
                            different locations and zones. You will then need to click onto the tenants tab and select
                            the edit button. You will need to scroll down to the bottom of the page, where you will see
                            the lock screen pin information.
                        </Typography>
                        <Typography pl={4.5}>
                            You will need to click onto the “Enable” button and ensure that you have typed in a pin.
                            Please note, the pin should be 6 digits long and this pin is to be used for anyone managing
                            the iPark tablet.
                        </Typography>
                        <img src="/assets/images/faqs/agent/iPark-1.png" alt="iPark 1" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Permits',
        content: [
            {
                question: 'What are the different recurring types?',
                answer: (
                    <Box pl={1.5}>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>One day permit </span> – Schedule permits for one
                                    day only, permits can be scheduled for a specific time period or all day.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Dynamic</span> – Schedule permits for daily,
                                    weekly, monthly, permits can be scheduled for a specific time period or all day.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Temporary</span> - The schedule allows for events
                                    to recur, with a maximum duration of 3 months at all times.
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <span style={{ fontWeight: 500 }}>Indefinite</span> - Schedule permits to recur
                                    indefinitely at all times.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                question: 'Do I have visibility of all the permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            {`Yes, you will have visibility to all the permits that exist on the system. The filtering option allows  you  to  drill  down  from  Company  >  Region  >  Location  >  Zone  >  Tenant.  You  also have the option to search by VRN below:`}
                        </Typography>
                        <img src="/assets/images/faqs/agent/permits-2.png" alt="Permits 2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I maximise my permit allocation?',
                answer: (
                    <Stack spacing={1} pl={4.5}>
                        <Typography>
                            You can maximise your permit allocation with our permit feature. For example, if you have
                            ten (10) maximum permits allocated, this means you can allocate permits to ten (10) unique
                            VRNs. Allocating multiple permits to one (1) VRN will only count as one (1) issued permit.
                        </Typography>
                        <Typography>
                            However, if a permit has been issued on a “One day” recurring type, this won’t use up your
                            permit allocation. Adding a permit on an “Indefinite” recurring permit type will permanently
                            use up your permit allocation.
                        </Typography>
                        <Typography>
                            Please note that it is advisable to only use the ‘Indefinite’ recurring type, when
                            necessary, as this will permanently use up the permit allocation available to you. If you
                            have permanent staff, then this would be the best use. However, if you have staff working on
                            different shift patterns, try and utilise the weekly and timed based permits for maximum
                            efficiency.
                        </Typography>
                    </Stack>
                ),
            },
            {
                question: 'How do I add a permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can create a permit with the information shown below by clicking into the ‘Permits’ tab
                            and ‘Add permits’:
                        </Typography>
                        <img src="/assets/images/faqs/agent/permits-4.1.png" alt="Permit 4.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            Once you have selected the above, the page below will then show you what details you need to
                            fill in to add a permit:
                        </Typography>
                        <img src="/assets/images/faqs/agent/permits-4.2.png" alt="Permit 4.2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I duplicate a permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can duplicate a permit by clicking onto the duplicate icon below: (The fields in your
                            duplicated permit will auto-populate and match the details from your original permit as seen
                            below).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-4.png" alt="Permit 4" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add a one-day permit?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the ‘Recurring type’ dropdown list and select ‘One day permit’
                            below: (Here, you can allocate a 1-day permit on a specific day between a time/date range).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-5.png" alt="Permit 5" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add dynamic permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Dynamic’. Here, you
                            have the option to select onto ‘Daily’, ‘Weekly’ or ‘Monthly’ permits.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Daily</span> – you can schedule the permit to recur every
                            ‘X’ number of days.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Weekly</span> - you can select onto the permit to recur on
                            specific days of the week.
                        </Typography>
                        <Typography pl={4.5}>
                            <span style={{ fontWeight: 500 }}>Monthly</span> - you can select the permit to recur on a
                            particular date in the month.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-6.png" alt="Permit 6" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add temporary permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Temporary’. Please
                            ensure you select a ‘Start date’ and ‘End date’.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-7.png" alt="Permit 7" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I add indefinite permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click into the recurring type dropdown list and select ‘Indefinite’.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-8.png" alt="Permit 8" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Why is my permit not saving?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to ensure that you have selected onto all required fields that are mandatory
                            before saving your permits, otherwise the system will not allow you to add a permit. Your
                            permit should show as ‘(Completed)’ below before being able to save:
                        </Typography>
                        <Typography pl={4.5} mt={2}>
                            Completed permit:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-11.1.png" alt="Permit 11.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            Not completed permit:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/permits-11.2.png" alt="Permit 11.2" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I add filters on the permit page to narrow down specific permits I need to view?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can add filters onto the permit page by clicking onto the ‘+’ button below:
                        </Typography>
                        <img src="/assets/images/faqs/agent/permits-12.png" alt="Permit 12" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I search by VRN?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can for specific VRNs by typing the VRN in the ‘Search by VRN’ box below:
                        </Typography>
                        <img src="/assets/images/faqs/agent/permits-13.png" alt="Permit 13" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I delete permits that have already been saved?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can do this by selecting onto the tick box next to the VRN name and select ‘Delete’
                            below: (You can also select onto the ‘Select all’ box to delete more than one permit in the
                            list).
                        </Typography>
                        <img src="/assets/images/faqs/agent/permits-14.png" alt="Permit 14" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I edit my current permits?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can edit your current permits by clicking onto the ‘Edit’ icon below: (Please note,
                            you cannot edit all permit information such as the ‘Recurring type’ and ‘Start date’).
                        </Typography>
                        <img src="/assets/images/faqs/agent/permits-15.png" alt="Permit 15" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'Can I reset my current filters? ',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, you can reset your filters by clicking onto the ‘Reset filter’ button below: (This will
                            remove any filters that you currently have on the page).
                        </Typography>
                        <img src="/assets/images/faqs/agent/permits-16.png" alt="Permit 16" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Tenant List',
        content: [
            {
                question: 'Where can I access all the tenant list information across all locations and zones?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to select onto the tenant list icon on the left hand side of the screen. Once
                            you have selected onto this, you will be able to filter by location and zone.
                        </Typography>
                        <Typography pl={4.5}>
                            Here, you can find their contact information and also their allocated permits. You also have
                            the option to export all data by clicking onto the ‘Export’ button below.
                        </Typography>
                        <img src="/assets/images/faqs/agent/tenant-list-1.png" alt="Tenant list 1" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Support',
        content: [
            {
                question: 'How do I contact my account manager?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click onto the ‘Support’ tab and then you will be directed to the page
                            below: (Here, you will need to select onto the dropdown menu ‘Location’ and ‘Company’ and
                            select the ‘ready to send’ button underneath the required account manager. Please note, the
                            ‘Subject’ and ‘Message’ field is a mandatory requirement).
                        </Typography>
                        <img src="/assets/images/faqs/tenant/support-1.png" alt="Support 1" width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I report a bug?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You will need to click onto the 'Report a bug' tab below. Here, you can provide a
                            description of your bug and upload any supporting screenshots so that we can help you to
                            best of our ability. Please note, our support email address will automatically appear when
                            you click onto this tab.
                        </Typography>
                        <img src="/assets/images/faqs/tenant/support-2.png" alt="Support 2" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Account',
        content: [
            {
                question: 'How do I change my password?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can change your password by clicking into the ‘Account’ tab and clicking onto ‘Reset
                            password’ below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-1.1.png" alt="Account 1.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            The below pop-up will appear for you to update your password:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-1.2.png" alt="Account 1.2 " width="100%" />
                    </Box>
                ),
            },
            {
                question: 'How do I change my phone number?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            You can change your phone number by clicking into the ‘Account’ tab and clicking onto
                            ‘Update account’ below:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-2.1.png" alt="Account 2.1" width="100%" />
                        <Typography pl={4.5} mt={2}>
                            The below pop-up will appear for you to update your account details:
                        </Typography>
                        <img src="/assets/images/faqs/tenant/account-2.2.png" alt="Account 2.2" width="100%" />
                    </Box>
                ),
            },
        ],
    },
    {
        subHeading: 'Accessing iHub platform from your mobile device',
        content: [
            {
                question: 'What is the URL link for logging into iHub on my mobile device?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Type in the iHub URL below into your mobile device’s browser - URL:{' '}
                            <Typography component="span" color="secondary">
                                <a href="https://ihub-insights-prod-ukpc.azurewebsites.net" target="blank">
                                    https://ihub-insights-prod-ukpc.azurewebsites.net
                                </a>
                            </Typography>
                        </Typography>
                    </Box>
                ),
            },
            {
                question: 'Can both iOS users and Android users access the platform from our mobile devices?',
                answer: (
                    <Box>
                        <Typography pl={4.5}>
                            Yes, both iOS users and Android users can access the platform. Please refer to the user
                            manual for further details on how to download the platform onto your device.
                        </Typography>
                    </Box>
                ),
            },
        ],
    },
];
