import { TableBody, TableCell, TableRow } from '@mui/material';
import { PCNsByServiceTypeStatistic } from '../../../models/PCNsByServiceTypeStatistic';
import { formatDate } from '../../../helpers/moment';
import { formatNumber } from '../../../helpers/FormatCurrency';

type Props = {
    pcnsByServiceTypeStatistic: PCNsByServiceTypeStatistic;
};

const width = 100 / 6;

export default function TablePCNsByServiceType(props: Props) {
    return (
        <TableBody>
            {props.pcnsByServiceTypeStatistic.items &&
                props.pcnsByServiceTypeStatistic.items.map((row, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell key={index} align="left" width={`${width}%`} component="th" scope="row">
                                {formatDate.getLocalDay(row.inDate)}
                            </TableCell>
                            <TableCell align="center" width={`${width}%`}>
                                {formatNumber.format(row?.qtyOfPOs)}
                            </TableCell>
                            <TableCell align="center" width={`${width}%`}>
                                {formatNumber.format(row?.qtyOfiTicketLite)}
                            </TableCell>
                            <TableCell align="center" width={`${width}%`}>
                                {formatNumber.format(row?.qtyOfEcam)}
                            </TableCell>
                            <TableCell align="center" width={`${width}%`}>
                                {formatNumber.format(row?.qtyOfANPR)}
                            </TableCell>
                            <TableCell align="center" width={`${width}%`}>
                                {formatNumber.format(row?.qtyOfSTS)}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
