import { Permit, PermitListItem } from './Permit';
import { FilterBasic, FilterRequest } from './../BaseFilter';
import { Filter } from '../Filter';
import { RecurringType } from './PermitRecurring';

export interface FilterPermits extends FilterBasic<PermitListItem> {
    filter?: {
        companyId: number;
        regionId: number;
        locationId: number;
        zoneIds: number[];
        validFrom: Date | undefined | null;
        validTo: Date | undefined | null;
        status: StatusFilter;
        type: string;
        shopId: number;
        recurringType: RecurringType | null;
    };
}

export interface AdvancedFilterOptions {
    isShowPermitType: boolean;
    isShowStatus: boolean;
    isShowFrom: boolean;
    isShowTo: boolean;
    isShowRecurringType: boolean;
}

export enum StatusFilter {
    All,
    Active,
    Inactive,
    Expired,
    Pending,
    Deleted,
}
