import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../hooks/useAuth';
import { Login } from '../../../models/Login';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { LanguageContext } from '../../../contexts/useLanguage';
import toast from 'react-hot-toast';

interface State {
    showPassword: boolean;
}

export default function LoginForm() {
    const navigate = useNavigate();

    const [params, setParams] = useSearchParams({});
    const email = params.get('email');

    const { loginWithEmail } = useContext(AuthContext);
    const [state, setState] = useState<State>({
        showPassword: false,
    });
    const { t } = useContext(LanguageContext);
    const RegisterSchema = Yup.object().shape({
        password: Yup.string().required(t('validation:passwordIsRequired')),
        username: Yup.string().required(t('validation:emailIsRequired')),
    });

    const formik = useFormik({
        initialValues: {
            username: email ?? '',
            password: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            const login: Login = {
                username: values.username.trim(),
                password: values.password,
            };
            loginWithEmail(login)
                .then((res) => {
                    toast.success(t('notification:loggedInSuccessfully'));
                })
                .catch((err) => {
                    if (err.response.status !== 500) toast.error(t('notification:loginFail'));
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setSubmitting } = formik;

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: { md: '60px 24px', lg: '80px 24px', xs: '15px 20px', sm: '40px 20px' },
                        // gap: { lg: '40px', md: '32px', xs: '30px' },
                        transition: 'all .5s',
                        '& #outlined-required-helper-text': {
                            position: 'absolute',
                            top: '35px',
                            left: 0,
                            fontSize: '12px',
                        },
                        '& #outlined-required11-helper-text': {
                            position: 'absolute',
                            top: '35px',
                            left: 0,
                            fontSize: '12px',
                        },
                    }}
                >
                    <Typography variant="h2">{t('loginPage:title.login')}</Typography>
                    <TextField
                        id="outlined-required"
                        label={t('loginPage:text.email')}
                        placeholder={t('input:enterEmail')}
                        sx={{ mt: { lg: '40px', md: '32px', xs: '16px' } }}
                        fullWidth
                        size="small"
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setParams({});
                            formik.handleChange(event);
                        }}
                        value={email ?? formik.values.username}
                    />
                    <TextField
                        sx={{ mt: { lg: '40px', md: '32px', xs: '24px' } }}
                        id="outlined-required11"
                        label={t('loginPage:text.password')}
                        placeholder={t('input:enterYourPassword')}
                        fullWidth
                        size="small"
                        type={state.showPassword ? 'text' : 'password'}
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{
                                            '& svg': {
                                                width: '16px',
                                                height: '16px',
                                            },
                                        }}
                                    >
                                        {state.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <LoadingButton
                        loading={isSubmitting}
                        loadingPosition="start"
                        startIcon={<></>}
                        variant="contained"
                        sx={{ width: 250, mt: { lg: '40px', md: '32px', xs: '24px' } }}
                        type="submit"
                    >
                        {t('loginPage:title.login')}
                    </LoadingButton>
                    <Typography variant="body1" mt={2} sx={{ cursor: 'pointer' }} onClick={() => navigate('forgot')}>
                        {t('loginPage:text.forgotPassword')}
                    </Typography>
                </Grid>
            </Form>
        </FormikProvider>
    );
}
