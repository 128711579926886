import { BaseHttpController } from 'ale-base-model';
import { Feedback, QA } from '../models/feedback/Feedback';
import { AxiosInstance } from 'axios';
import { Pagination } from '../models/BaseFilter';
import { questions } from '../pages/feedback/components/QuestionComponent';

export interface FilterFeedbackProps {
    from?: Date;
    to?: Date;
    search?: string;
    page?: number;
    pageSize?: number;
}

export class FeedBackController extends BaseHttpController<Feedback> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'FeedBack', client);
    }

    async filter(filter: FilterFeedbackProps): Promise<Pagination<Feedback>> {
        const { data } = await this.doPost({ path: 'Filter', body: filter, config: {} });
        const content = (jsonContent: string) => {
            const _content: QA[] = (JSON.parse(jsonContent) as QA[]).map((qa) => ({
                ...qa,
                question: questions.find((q) => q.Id === qa.questionId),
            }));

            return _content;
        };

        return {
            ...data,
            data: data.data.map((d: any) => ({
                ...d,
                content: content(d.content),
            })),
        };
    }
    async upsert(f: Feedback): Promise<Feedback> {
        const feedback = { ...f, content: JSON.stringify(f.content) };
        const res = await this.doPost({
            path: 'Upsert',
            body: feedback,
            config: {},
        });
        return res.data;
    }
}
