import { Avatar, Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useState } from 'react';
import { appConfig } from '../../../config/AppConfig';
import color from '../../../config/Colors';
import { formatDate } from '../../../helpers/moment';
import { AuthContext } from '../../../hooks/useAuth';
import { Feedback, QA } from '../../../models/feedback/Feedback';
import useMedia from '../../../hooks/useMedia';
type Props = {
    feedbackItem?: Feedback;
};

export default function FeedBackItem({ feedbackItem }: Props) {
    const { getAvatar } = useContext(AuthContext);

    const avatarUrl = `${appConfig.gateway.apiAzureMedia}/${feedbackItem?.createdBy}`;

    const sliceZoneName = feedbackItem?.zoneName?.split(',');
    const sliceLocationName = feedbackItem?.locationName?.split(',');
    const sliceLabel = feedbackItem?.label?.split(',');

    const [tooltipOpenLabel, setTooltipOpenLabel] = useState(false);
    const [tooltipOpenLocation, setTooltipOpenLocation] = useState(false);
    const [tooltipOpenZone, setTooltipOpenZone] = useState(false);

    const { isMobileSM, isMobileSM_UP } = useMedia();

    const handleHoverEnterLabel = () => {
        if (isMobileSM_UP || isMobileSM) {
            setTooltipOpenLabel(true);
        }
    };

    const handleHoverLeaveLabel = () => {
        if (isMobileSM_UP || isMobileSM) {
            setTooltipOpenLabel(false);
        }
    };

    const handleHoverEnterLocation = () => {
        if (isMobileSM_UP || isMobileSM) {
            setTooltipOpenLocation(true);
        }
    };

    const handleHoverLeaveLocation = () => {
        if (isMobileSM_UP || isMobileSM) {
            setTooltipOpenLocation(false);
        }
    };

    const handleHoverEnterZone = () => {
        if (isMobileSM_UP || isMobileSM) {
            setTooltipOpenZone(true);
        }
    };

    const handleHoverLeaveZone = () => {
        if (isMobileSM_UP || isMobileSM) {
            setTooltipOpenZone(false);
        }
    };

    return (
        <Stack
            sx={{
                padding: '16px 24px',
                backgroundColor: color.grey100,
                width: '100%',
                borderRadius: '8px',
            }}
        >
            <Grid container xs={12} borderRadius={1} justifyContent={'space-between'}>
                <Grid
                    item
                    xs={12}
                    md={3.7}
                    marginBottom={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    borderRadius={1}
                    p={2}
                    bgcolor={color.white}
                >
                    <Stack direction={'column'} spacing={'12px'}>
                        <Stack direction={'row'} spacing={2}>
                            <Avatar
                                sx={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                alt={feedbackItem?.name}
                                src={avatarUrl}
                            />
                            <Stack direction={'column'}>
                                <Typography
                                    sx={{
                                        color: color.darkPrimary,
                                        width: { xs: 'auto', sm: 'auto', md: '140px', lg: '140px' },
                                        textAlign: 'left',
                                    }}
                                    variant="h5"
                                >
                                    {feedbackItem?.name}
                                </Typography>

                                <Typography
                                    sx={{
                                        color: color.grey600,
                                        textAlign: 'left',
                                        wordBreak: 'break-word',
                                    }}
                                    variant="body2"
                                >
                                    {feedbackItem?.email}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}
                        />

                        {/* Created date */}
                        <Grid container item xs={12}>
                            <Grid item xs={6} sm={4} pr={1}>
                                <Typography
                                    sx={{
                                        width: { xs: 'auto', sm: 'auto' },
                                        textAlign: 'left',
                                    }}
                                    variant="body2"
                                    color={color.textPrimary}
                                >
                                    Created date:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                                <Typography
                                    sx={{
                                        width: { xs: 'auto', sm: 'auto' },
                                        textAlign: 'left',
                                    }}
                                    variant="body2"
                                    color={color.textPrimary}
                                    flexWrap={'wrap'}
                                >
                                    {formatDate.convertStellaToGMT(feedbackItem?.created!)}
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Label */}
                        <Grid container item xs={12}>
                            <Grid item xs={6} sm={4} pr={1}>
                                <Typography
                                    sx={{
                                        width: { xs: 'auto', sm: 'auto' },
                                        textAlign: 'left',
                                    }}
                                    variant="body2"
                                    color={color.textPrimary}
                                >
                                    Label:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                                <Tooltip
                                    title={
                                        sliceLabel && sliceLabel.length > 1
                                            ? sliceLabel.slice(1).join(', ')
                                            : sliceLabel || 'N/A'
                                    }
                                    open={tooltipOpenLabel}
                                    arrow
                                >
                                    <Typography
                                        sx={{
                                            width: 'max-content',
                                            textAlign: 'left',
                                            wordBreak: 'break-word',
                                            cursor: 'pointer',
                                        }}
                                        variant="body2"
                                        color={color.textPrimary}
                                        onMouseEnter={handleHoverEnterLabel}
                                        onMouseLeave={handleHoverLeaveLabel}
                                    >
                                        {sliceLabel && sliceLabel.length > 0
                                            ? sliceLabel.length > 1
                                                ? `${sliceLabel[0]}...`
                                                : sliceLabel[0]
                                            : 'N/A'}
                                        {sliceLabel && sliceLabel.length > 1 && (
                                            <Box component="span">+{sliceLabel.length - 1}</Box>
                                        )}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        {/* Location */}
                        <Grid container item xs={12}>
                            <Grid item xs={6} sm={4} pr={1}>
                                <Typography
                                    sx={{
                                        width: { xs: 'auto', sm: 'auto' },
                                        textAlign: 'left',
                                    }}
                                    variant="body2"
                                    color={color.textPrimary}
                                >
                                    Location:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                                <Tooltip
                                    title={
                                        sliceLocationName && sliceLocationName.length > 1
                                            ? sliceLocationName.map((item, index) => (
                                                  <span key={index}>
                                                      {index > 0}
                                                      {item}
                                                      <br />
                                                  </span>
                                              ))
                                            : sliceLocationName || 'N/A'
                                    }
                                    open={tooltipOpenLocation}
                                    arrow
                                >
                                    <Typography
                                        sx={{
                                            width: 'max-content',
                                            textAlign: 'left',
                                            wordBreak: 'break-word',
                                            cursor: 'pointer',
                                        }}
                                        variant="body2"
                                        color={color.textPrimary}
                                        onMouseEnter={handleHoverEnterLocation}
                                        onMouseLeave={handleHoverLeaveLocation}
                                    >
                                        {sliceLocationName && sliceLocationName.length > 0
                                            ? sliceLocationName.length > 1
                                                ? `${sliceLocationName[0]}...`
                                                : sliceLocationName[0]
                                            : 'N/A'}
                                        {sliceLocationName && sliceLocationName.length > 1 && (
                                            <Box component="span">+{sliceLocationName.length - 1}</Box>
                                        )}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        {/* Zone */}
                        <Grid container item xs={12}>
                            <Grid item xs={6} sm={4} pr={1}>
                                <Typography
                                    sx={{
                                        width: { xs: 'auto', sm: 'auto' },
                                        textAlign: 'left',
                                    }}
                                    variant="body2"
                                    color={color.textPrimary}
                                >
                                    Zone:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                                <Tooltip
                                    title={
                                        sliceZoneName && sliceZoneName.length > 1
                                            ? sliceZoneName.map((item, index) => (
                                                  <span key={index}>
                                                      {index > 0}
                                                      {item}
                                                      <br />
                                                  </span>
                                              ))
                                            : sliceZoneName || 'N/A'
                                    }
                                    open={tooltipOpenZone}
                                    arrow
                                >
                                    <Typography
                                        sx={{
                                            width: { xs: 'max-content', md: 'auto' },
                                            textAlign: 'left',
                                            wordBreak: 'break-word',
                                            cursor: 'pointer',
                                        }}
                                        variant="body2"
                                        color={color.textPrimary}
                                        onMouseEnter={handleHoverEnterZone}
                                        onMouseLeave={handleHoverLeaveZone}
                                    >
                                        {sliceZoneName && sliceZoneName.length > 0
                                            ? sliceZoneName.length > 1
                                                ? `${sliceZoneName[0]}...`
                                                : sliceZoneName[0]
                                            : 'N/A'}
                                        {sliceZoneName && sliceZoneName.length > 1 && (
                                            <Box component="span">+{sliceZoneName.length - 1}</Box>
                                        )}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={8} borderRadius={1} alignItems={'center'} p={2} bgcolor={color.white}>
                    <Stack gap={2}>{feedbackItem?.content.map((qa, index) => QuestionItem(index + 1, qa))}</Stack>
                </Grid>
            </Grid>
        </Stack>
    );
}

export function QuestionItem(index: number, qa: QA) {
    return (
        <Stack>
            <Typography sx={{ fontWeight: 400 }}>
                {index}. {qa.question?.question}
            </Typography>
            <Typography sx={{ pl: 3, color: color.grey600 }}>{qa.answer}</Typography>
        </Stack>
    );
}
