import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { QA } from '../../../models/feedback/Feedback';
import { questions } from './QuestionComponent';
import { feedbackController } from '../../../controllers';
import UseAuth, { AuthContext } from '../../../hooks/useAuth';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

export type FormValues = {
    feedback: QA[];
};

type Props = {};

const FeedbackForm = (props: Props) => {
    const authData = useContext(AuthContext);

    const [isOpen, setIsOpen] = useState(false);

    const hookForm = useForm<FormValues>({ mode: 'all', defaultValues: { feedback: [] } });
    const [isLoading, setIsLoading] = useState(false);

    const fieldArray = useFieldArray({
        control: hookForm.control,
        name: 'feedback',
    });

    const disabled = !(hookForm.formState.isDirty && hookForm.formState.isValid);

    const onSubmit = (data: FormValues) => {
        setIsLoading(true);
        feedbackController
            .upsert({
                id: 0,
                content: data.feedback.map((f) => ({ questionId: f.questionId, answer: f.answer } as QA)),
                email: authData.account.email,
                name: authData.account.firstName + ' ' + authData.account.lastName,
                createdBy: authData.account.accountId,
            })
            .then((res) => {
                toast.success('Feedback has been recorded');
                setIsOpen(false);
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        hookForm.reset({ feedback: questions.map((q) => ({ question: q, questionId: q.Id })) });
    }, []);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setIsOpen(true);
                    hookForm.reset({ feedback: questions.map((q) => ({ question: q, questionId: q.Id, answer: '' })) });
                }}
            >
                Add Feedback
            </Button>

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    '& .MuiPaper-root': {
                        padding: '24px',
                    },
                }}
                PaperProps={{ sx: { maxWidth: 500 } }}
                maxWidth={'sm'}
            >
                <DialogTitle id="alert-dialog-title" sx={{ p: 0, textAlign: 'center' }}>
                    <Typography color="primary" variant="h4">
                        Feedback on iHub experience
                    </Typography>

                    <Typography color="GrayText">We always value your feedback to improve our services.</Typography>
                </DialogTitle>
                <DialogContent sx={{ p: 0, mt: 3 }}>
                    <Stack spacing={2}>
                        {fieldArray.fields.map((feedback, index) => {
                            const Component = feedback.question!.Component;
                            return (
                                <Component
                                    key={feedback.questionId}
                                    index={index}
                                    feedback={feedback}
                                    hookForm={hookForm}
                                />
                            );
                        })}
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 0, mt: 3, justifyContent: 'space-between' }}>
                    <Button sx={{ minWidth: 120 }} variant="cancel" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>

                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<></>}
                        variant="contained"
                        sx={{ minWidth: '120px' }}
                        disabled={disabled}
                        onClick={hookForm.handleSubmit(onSubmit)}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FeedbackForm;
