import { AllocatedPermitType } from '../../../../../models/tenant/AllocatedTypeWithAmount';

export const getLabelAllocatedTypeName = (allocatedType: AllocatedPermitType) => {
    if (allocatedType === AllocatedPermitType.Once) return 'One day permit';
    if (allocatedType === AllocatedPermitType.Dynamic) return 'Dynamic';
    if (allocatedType === AllocatedPermitType.Indefinite) return 'Indefinite';
    if (allocatedType === AllocatedPermitType.Temporary) return 'Temporary';

    return '';
};
