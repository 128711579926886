import { BaseModel } from './BaseModel';
import { BaseLocationDetail } from './Company';
import { Service } from './Service';
import Zone from './Zone';

export interface LocationWithDetail extends Location {
    totalZones: number;
    // serviceTypes: number[]
    regionName?: string;
}

export interface LocationWithZones {
    location: Location[];
    zones: Zone[];
}

export interface LocationWithAllZone extends Omit<Location, 'zones'> {
    zones: Zone[];
}

export interface Location extends BaseLocationDetail {
    companyId?: number;
    brandId: number;
    regionId: number;
    region: any;
    externalReference: string;
    priority: number;
    name: string;
    longitude: number;
    latitude: number;
    isGeoPositionRefined: boolean;
    locationStatus: LocationStatus;
    jurisdiction: number;
    initialLegislation: number;
    notes: string;
    technicalNotes: string;
    alertReasonId: number;
    alertNotes: string;
    sectorId: number;
    transferredToLocationId: number;
    pendingAppealWorkQueue: number;
    zones: number;
}

export enum LocationStatus {
    Prospective,
    Live,
    Transferred,
    Decommissioned,
    OnHold,
}
