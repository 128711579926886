import { Box, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import color from '../../../config/Colors';
import { intelliparkLogo, ukpcLogo } from '../../../config/ImageFromEnv';
import { LanguageContext } from '../../../contexts/useLanguage';
import useMedia from '../../../hooks/useMedia';

export default function Footer() {
    const { t } = useContext(LanguageContext);
    const { isMobileSM, isMDOnly } = useMedia();

    return (
        <Box
            width={'100%'}
            mt={5}
            sx={{ background: color.white, height: 'max-content', borderTop: `1px solid ${color.grey300}` }}
        >
            <Stack
                p={2}
                spacing={1}
                direction={isMobileSM ? 'column' : 'row'}
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    color={color.textPrimary}
                    variant="body1"
                    fontWeight={400}
                    align={isMobileSM ? 'center' : 'left'}
                    fontSize={{ xs: '12px', sm: '12px', md: '14px' }}
                >
                    {' '}
                    {t(`footer:text.line1`, {
                        year: (new Date()).getFullYear(),
                    })}
                    <br /> {t('footer:text.line2')}
                </Typography>
                <Stack direction="row" alignItems="center">
                    <Typography
                        color={color.textPrimary}
                        variant="body1"
                        fontWeight={400}
                        fontSize={{ xs: '12px', sm: '12px', md: '14px' }}
                        component={'pre'}
                    >
                        Powered by
                    </Typography>
                    <Box
                        sx={{
                            width: 120,
                            height: 30,
                            background: `url(${intelliparkLogo})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                        }}
                    />
                </Stack>
            </Stack>
        </Box>
    );
}
