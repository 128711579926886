import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import { anprDashboardDownload, pcnDashboardDownloadController } from '../../../controllers';
import { ConvertToGMT0Time } from '../../../helpers';
import { BlobFile } from '../../../helpers/BlobFileHepler';
import { Filter } from '../../../models/Filter';
import { ReportContext } from './useReport';

export default function useExportMedia() {
    const { t } = useContext(LanguageContext);
    const { filterState, setState } = useContext(ReportContext);
    const fileType = (isPdf: boolean, fileName: string) => {
        return isPdf ? `${fileName}.pdf` : `${fileName}.xlsx`;
    };
    const convertFilterRF = () => {
        const filterConvert: Filter = {
            companyId: filterState.companyId ?? 0,
            regionId: filterState.regionId ?? 0,
            locationId: filterState.locationId ?? 0,
            from: ConvertToGMT0Time(filterState.from!),
            to: ConvertToGMT0Time(filterState.to!),
            zoneIds: filterState.zoneIds ?? [],
        };
        return filterConvert;
    };
    // Export excel
    const exportExcelDailyVisits = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.dailyVisitsReport'));
        await anprDashboardDownload
            .DownloadDailyVisits({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadAverageLengthOfVisits = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.averageLengthOfVisitsReport'));
        await anprDashboardDownload
            .DownloadAverageLengthOfVisits({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadCapacityAnalysis = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.CapacityAnalysisReport'));
        await anprDashboardDownload
            .DownloadCapacityAnalysis({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadRepeatVisit = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.repeatVisitsReport'));
        await anprDashboardDownload
            .DownloadRepeatVisit({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    // More export for UKPC
    const exportExcelDownloadGroupCancelledPCNByActor = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.cancellationsReport'));
        await pcnDashboardDownloadController
            .DownloadGroupCancelledPCNByActor({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGroupPCNByStatus = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.pncReport'));
        await pcnDashboardDownloadController
            .DownloadGroupPCNByStatus({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGroupPCNByReason = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.pcnByReasonsReport'));
        await pcnDashboardDownloadController
            .DownloadGroupPCNByReason({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGroupPCNByZone = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.pcnByZonesReport'));
        await pcnDashboardDownloadController
            .DownloadGroupPCNByZone({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadEVDailyVisits = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('dashboard:text.dailyEVVisits'));
        await anprDashboardDownload
            .DownloadGroupEVDailyVisits({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadEVAverageLength = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('dashboard:text.averageLengthOfEVVisits'));
        await anprDashboardDownload
            .DownloadGroupEVAverageLength({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGrossRevenue = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, 'Gross revenue - STs');
        await anprDashboardDownload
            .DownloadGrossRevenue({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGrossRevenueByLongTerm = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('dashboard:title.ActiveLongTermBooking'));
        await anprDashboardDownload
            .DownloadGrossRevenueByLongTerm({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };

    const exportExcelDownloadGrossRevenueByLts = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, 'Gross revenue - LTs');
        await anprDashboardDownload
            .DownloadGrossRevenueByLts({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };

    const exportExcelDownloadPCNsByServiceType = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.pcnsByServiceTypeReport'));
        await pcnDashboardDownloadController
            .DownloadGroupPCNsByServiceType({ filter: convertFilterRF(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };

    return {
        exportExcelDailyVisits,
        exportExcelDownloadAverageLengthOfVisits,
        exportExcelDownloadCapacityAnalysis,
        exportExcelDownloadRepeatVisit,
        exportExcelDownloadGroupCancelledPCNByActor,
        exportExcelDownloadGroupPCNByStatus,
        exportExcelDownloadGroupPCNByReason,
        exportExcelDownloadGroupPCNByZone,
        exportExcelDownloadEVDailyVisits,
        exportExcelDownloadEVAverageLength,
        exportExcelDownloadGrossRevenue,
        exportExcelDownloadGrossRevenueByLongTerm,
        exportExcelDownloadGrossRevenueByLts,
        exportExcelDownloadPCNsByServiceType,
    };
}
