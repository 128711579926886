import { Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import UseLanguage, { LanguageContext } from '../../../../../contexts/useLanguage';

export default function RecurWithTooltip() {
    const { t } = useContext(LanguageContext);
    return (
        <Tooltip title="This is how often you want the permit to repeat. For example, if you input “1” in the input box, this means the permits will repeat every week on the days you have selected. Alternatively, if you input “2”, then the permits will repeat every other week, that is every 2 weeks on the days you have selected.">
            <Typography variant="body1" component={'span'} sx={{ cursor: 'help' }}>
                {t('permits:text.recurEvery')}
            </Typography>
        </Tooltip>
    );
}
