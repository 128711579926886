import { Typography, TextField, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';
import { QA, Question, QuestionType } from '../../../models/feedback/Feedback';
import { FormValues } from './FeedbackForm';

export type BaseQuestionProps<A = any> = {
    feedback: QA<A>;
    index: number;
    hookForm: UseFormReturn<FormValues, any>;
    children?: ReactNode;
};

const BaseQuestion = (props: BaseQuestionProps) => {
    const { index } = props;

    return (
        <Stack spacing={1}>
            <Typography>
                {index + 1}. {props.feedback.question?.question}
            </Typography>
            {props.children}
        </Stack>
    );
};

const Question1 = (props: BaseQuestionProps<string>) => {
    const { index, hookForm } = props;

    return (
        <BaseQuestion
            {...props}
            children={
                <Controller
                    name={`feedback.${index}.answer`}
                    control={hookForm.control}
                    rules={{
                        required: {
                            value: true,
                            message: 'This field is required.',
                        },
                        // validate: validateTypingInput,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            multiline
                            value={field.value || ''}
                            placeholder={'Enter your answer'}
                            // maxLength={50}
                            rows={3}
                            error={!!error?.message}
                            helperText={error?.message}
                            sx={{
                                '& .MuiInputBase-root': {
                                    p: 0,
                                },
                            }}
                        />
                    )}
                />
            }
        />
    );
};

const Question2 = (props: BaseQuestionProps<string>) => {
    const { index, feedback, hookForm } = props;

    return (
        <BaseQuestion
            {...props}
            children={
                <Controller
                    name={`feedback.${index}.answer`}
                    control={hookForm.control}
                    rules={
                        {
                            // required: {
                            //     value: true,
                            //     message: 'This field is required.',
                            // },
                            // validate: validateTypingInput,
                        }
                    }
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            multiline
                            value={field.value || ''}
                            placeholder={'Enter your answer'}
                            // maxLength={50}
                            rows={3}
                            error={!!error?.message}
                            helperText={error?.message}
                            sx={{
                                '& .MuiInputBase-root': {
                                    p: 0,
                                },
                            }}
                        />
                    )}
                />
            }
        />
    );
};

export const questions: Question[] = [
    {
        Id: 1,
        question: 'In what ways can we make your experience on iHub better?',
        type: QuestionType.TextArea,
        Component: Question1,
    },
    {
        Id: 2,
        question: 'What features can we implement in the future to make the iHub platform more valuable to you?',
        type: QuestionType.TextArea,
        Component: Question2,
    },
];
