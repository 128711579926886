import React, { useContext } from 'react';
import { Chart as ChartJS, registerables, ChartOptions, ChartData, TooltipItem } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import font from '../../config/Fonts';
import useMedia from '../../hooks/useMedia';
import { StatisticItems } from '../../models/StatisticItems';
import { LIST_COLOR_PNC_CANCEL_CHART } from '../../constants';
import { Box, Stack, Typography } from '@mui/material';
import color from '../../config/Colors';
import { LanguageContext } from '../../contexts/useLanguage';
import { ChartShowType } from '../../models/ChartShowType';

ChartJS.register(...registerables);
type Props = {
    chartShowType?: ChartShowType;
    statisticItems: StatisticItems;
    listColor?: string[];
};
export const getPercent = (val: number, data: StatisticItems) => {
    const total = data.items.reduce((t, c) => (t += c.amount), 0);
    return Number(((val / total) * 100).toFixed(1));
};
export const checkIsEmpty = (data: StatisticItems) => {
    const total = data.items.reduce((t, c) => (t += c.amount), 0);
    return data.items == null || total == 0;
};

export function DoughnutChart(props: Props) {
    const { t } = useContext(LanguageContext);
    const { isMobileSM } = useMedia();
    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                displayColors: true,
                callbacks: {
                    label(tooltipItems: any) {
                        return ` ${tooltipItems.label}: ${tooltipItems.formattedValue} %`;
                    },
                },
            },
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },

            datalabels: {
                display: false,
                color: 'white',
                anchor: 'center',
                align: 'top',
                font: {
                    family: font,
                    size: isMobileSM ? 8 : 10,
                    weight: 'bold',
                },
                formatter(value, ctx) {
                    return value.toFixed(1) + '%';
                },
            },
        },
        //disable inside border
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
    };

    const data: ChartData<'doughnut', number[], string> = {
        labels: props.statisticItems.items.map((item, index) => item.label),
        datasets: [
            {
                label: '# of Votes',
                data: props.statisticItems.items.map((item, index) => getPercent(item.amount, props.statisticItems)),
                backgroundColor: props.listColor ?? LIST_COLOR_PNC_CANCEL_CHART,
            },
        ],
    };
    return checkIsEmpty(props.statisticItems) ? (
        <Stack
            sx={{
                border: `1px solid #ddd`,
                width: '200px',
                height: '200px',
                margin: 'auto',
                borderRadius: '50%',
                background: color.grey300,
            }}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Typography variant="h5" sx={{ color: color.grey600 }}>
                {t('companies:text.noDataToDisplay')}
            </Typography>
        </Stack>
    ) : (
        <Doughnut options={options} data={data} plugins={[ChartDataLabels as any]} />
    );
}
