import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Chip, CircularProgress, styled, Typography } from '@mui/material';
import color from '../../config/Colors';
import { v4 } from 'uuid';
import _ from 'lodash';
import { LanguageContext } from '../../contexts/useLanguage';
import { BiCaretDown } from 'react-icons/bi';
import { Location, LocationStatus } from '../../models/Location';

type Props<T> = {
    listData: T[];
    itemValue?: T;
    keyEqual: keyof T;
    keyLabel: keyof T;
    label: string | JSX.Element;
    disabled?: boolean;
    setFilter: (option?: T) => void;
    defaultData?: T;
    key?: any;
    isLoading?: boolean;

    //all options
    isHaveAllOptions?: boolean;
    allOptionLabel?: string;
    open?: boolean;
    subDescLabels?: (keyof T)[];
    keyStatus: keyof T;
};

export default function IAutoCompleteLocation<T>(props: Props<T>) {
    const { t } = useContext(LanguageContext);
    const handleChange = (event: any, value: T) => {
        if (!value[props.keyEqual]) {
            return props.setFilter(undefined);
        }
        return props.setFilter(value);
    };

    const getAllOptions = () => {
        const allOption: T = {} as T;
        allOption[props.keyEqual] = undefined as any;
        allOption[props.keyLabel] = props.allOptionLabel as any;
        return allOption;
    };

    const listData = props.isHaveAllOptions ? [getAllOptions()].concat(props.listData) : props.listData;

    return (
        <BoxIAutoComplete>
            <Autocomplete
                fullWidth
                disablePortal
                noOptionsText={t('dashboard:input.noOptions')}
                open={props.open}
                sx={{}}
                disabled={props.disabled || props.isLoading}
                defaultValue={props.listData[0]}
                options={listData}
                value={props.itemValue ?? listData[0]}
                getOptionLabel={(option) => (option[props.keyLabel] as unknown as string) || ''}
                isOptionEqualToValue={(option, value) => option[props.keyEqual] == value[props.keyEqual]}
                renderOption={(propss: React.HTMLAttributes<HTMLLIElement>, option, { selected }) => {
                    const isAllItem = option[props.keyEqual] === undefined;
                    return (
                        <Box key={Number(option[props.keyEqual])}>
                            {isAllItem && (
                                <li
                                    {...propss}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        position: 'absolute',
                                        top: 0,
                                        backgroundColor: selected ? color.lightPrimary : color.white,
                                        width: props.listData?.length > 6 ? 'calc(100% - 18px)' : 'calc(100% - 10px)',
                                        padding: '9px 16px',
                                    }}
                                    key={'all'}
                                >
                                    {option[props.keyLabel] as unknown as string}
                                </li>
                            )}

                            <li
                                {...propss}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    // alignItems: 'flex-start',
                                    backgroundColor: selected ? color.lightPrimary : color.white,
                                }}
                            >
                                {option[props.keyLabel] as unknown as string}
                                {props.keyStatus &&
                                    LocationChipStatus(option[props.keyStatus] as unknown as number as LocationStatus)}
                            </li>
                        </Box>
                    );
                }}
                onChange={(event, value) => {
                    if (value !== null) handleChange(event, value);
                }}
                renderInput={(params) => <TextField {...params} label={props.label} />}
                popupIcon={props.isLoading ? <CircularProgress size={'18px'} /> : <BiCaretDown fontSize={14} />}
                size="small"
            />
        </BoxIAutoComplete>
    );
}

export const BoxIAutoComplete = styled(Box)({
    '& #combo-box-demo-listbox': {
        padding: '5px',
    },
    '& .MuiAutocomplete-listbox': {
        padding: '5px',
    },
    '& .MuiAutocomplete-option': {
        minHeight: 'auto !important',
        mb: '2px',
        borderBottom: `1px solid ${color.grey200}`,
        borderRadius: '4px',
    },
    //css first item chosen
    '& .MuiAutocomplete-groupLabel': {
        fontSize: '14px',
        lineHeight: 'normal',
        padding: '10px 20px',
        color: 'black',
        // fontWeight: 600,
        background: '#E5F2F8',
        my: '2px',
    },
    width: '100%',
});

const LocationChipStatus = (status: LocationStatus) => {
    const getInfo = (status: LocationStatus) => {
        switch (status) {
            case LocationStatus.Decommissioned:
                return {
                    background: color.lightDanger,
                    color: color.danger,
                    name: 'Decommissioned',
                };
            case LocationStatus.Live:
                return {
                    background: color.lightPrimary,
                    color: color.success,
                    name: 'Live',
                };
            case LocationStatus.OnHold:
                return {
                    background: 'rgba(255, 182, 0, 0.1)',
                    color: '#FFB600',
                    name: 'On Hold',
                };
            case LocationStatus.Prospective:
                return {
                    background: 'rgba(0, 123, 255, 0.1)',
                    color: '#007BFF',
                    name: 'Prospective',
                };
            default:
                return {
                    background: 'rgba(204, 0, 102, 0.1)',
                    color: '#CC0066',
                    name: 'Prospective',
                };
        }
    };

    return (
        <Box sx={{ p: '2px 10px', background: getInfo(status).background, borderRadius: 2, ml: 2 }}>
            <Typography variant="body2" textTransform={'capitalize'} color={getInfo(status).color}>
                {getInfo(status).name}
            </Typography>
        </Box>
    );
};
