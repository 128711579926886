/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import MyModalLarge from '../../../components/modal/MyModalLarge';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AuthContext } from '../../../hooks/useAuth';
import { Role } from '../../../models/permission/Role';
import ManagedByUKPC from '../components/ManagedByUKPC';
import ConfirmDialogLargeOneOptions from './components/ConfirmDialogLargeOneOptions';
import ModalCheckVRNUpsert from './components/ModalCheckVRNUpsert';
import RedirectNoneTenants from './components/RedirectNoneTenants';
import UpsertPermitActions from './components/UpsertPermitActions';
import UpsertPermitFilter from './components/UpsertPermitFilter';
import UpsertPermitInfo from './components/UpsertPermitInfo';
import UpsertPermitItems from './components/UpsertPermitItems';
import useUpsertPermit, { UpsertPermitContext } from './hooks/useUpsertPermit';

export default function UpsertPermit() {
    const { t } = useContext(LanguageContext);
    const upsertPermitData = useUpsertPermit();
    const {
        isEdit,
        modalWarning,
        vrnCheckingList,
        handleConfirmAddNewPermits,
        handleCancelModalConfirm,
        isNoneTenants,
        setIsNoneTenants,
        isFromStel,
        handleClearOldParams,
        stateDialog,
        handleConfirm,
        filterState,
        formControl,
    } = upsertPermitData;
    const { account } = useContext(AuthContext);

    //delete old params when leave page
    useEffect(() => {
        return () => {
            handleClearOldParams();
        };
    }, []);

    return (
        <UpsertPermitContext.Provider value={upsertPermitData}>
            <FormProvider {...formControl}>
                <>
                    <MyModalLarge
                        key={1}
                        open={modalWarning.open}
                        handleOpen={modalWarning.handleOpen}
                        handleClose={handleCancelModalConfirm}
                        modalContent={
                            <ModalCheckVRNUpsert
                                handleClose={handleCancelModalConfirm}
                                vrnCheckingList={vrnCheckingList}
                                handleConFirm={handleConfirmAddNewPermits}
                            />
                        }
                    />
                    <ConfirmDialogLargeOneOptions
                        open={stateDialog.open}
                        title={t('permits:upload.warning')}
                        content={t('permits:text.YourAccountDoesNotHave')}
                        onClose={handleConfirm}
                        onConfirm={handleConfirm}
                    />

                    <Stack direction={'row'} gap={1.5}>
                        <Typography variant="h3" fontWeight={400}>
                            {isEdit ? t('permits:title.editPermit') : t('permits:button.addPermits')}
                        </Typography>
                        <ManagedByUKPC />
                    </Stack>
                    <Stack my={2}>
                        <MyBreadcrumbs />
                    </Stack>
                    <Grid container>
                        <Grid item xs={12} mt={2}>
                            <UpsertPermitFilter
                                setIsNoneTenants={setIsNoneTenants}
                                isFromStel={isFromStel}
                                handleClearOldParams={handleClearOldParams}
                            />

                            {/* <FilterUpsertNew /> */}
                        </Grid>

                        {isNoneTenants && <RedirectNoneTenants />}

                        {/* {!isGoldRole() && isFromStel && <RedirectPermissionRequired />} */}
                        {filterState.shopId && account.label !== Role.Tenant ? (
                            <Grid item xs={12} mt={3}>
                                <UpsertPermitInfo />
                            </Grid>
                        ) : (
                            <></>
                        )}

                        {account.label === Role.Tenant && (
                            <Grid item xs={12} mt={3}>
                                <UpsertPermitInfo />
                            </Grid>
                        )}

                        {!isNoneTenants && (
                            <>
                                <Grid item mt={3} container>
                                    <UpsertPermitItems />
                                </Grid>
                                <Grid item container>
                                    <UpsertPermitActions />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            </FormProvider>
        </UpsertPermitContext.Provider>
    );
}
