import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables, ChartOptions, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import font from '../../../config/Fonts';
import useMedia from '../../../hooks/useMedia';
import { AnprMatchesDailyStatsByHours } from '../../../models/AnprMatchesDailyStats';
import ScrollContainer from 'react-indiana-drag-scroll';
import _ from 'lodash';
import { COLOR_CAPACITY } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';
import { formatDate } from '../../../helpers/moment';
import color from '../../../config/Colors';
ChartJS.register(...registerables);

type Props = {
    data: AnprMatchesDailyStatsByHours;
    handleClose: () => void;
};

export default function ModalDetailCapacity(props: Props) {
    const { isMobileSM, isMediumMD } = useMedia();
    const options: ChartOptions<'bar'> = {
        responsive: true,
        // set height not crash
        maintainAspectRatio: false,
        plugins: {
            //note
            legend: {
                display: false,
                position: 'bottom' as const,
                align: 'center',
                //set note label font
                labels: {
                    font: {
                        family: font,
                        size: isMobileSM ? 12 : isMediumMD ? 14 : 16,
                    },
                    padding: isMobileSM ? 10 : 20,
                    boxWidth: 5,
                    boxHeight: 5,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },
            // title: {
            //     display: true,
            //     text: 'Line Chart',
            // },

            // Top Label
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                font: {
                    family: font,
                    size: isMobileSM ? 10 : 12,
                },
                formatter(value, context) {
                    return value;
                },
            },
            tooltip: {
                callbacks: {
                    labelTextColor: function (context) {
                        return 'white';
                    },
                    label: function (context) {
                        return `  ${context.formattedValue} %`;
                    },
                },
                // boxWidth: 30,
                footerSpacing: 5,
                // bodyAlign: 'center',
                titleAlign: 'center',
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        //setting grid
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: font,
                    },
                    //format style label
                    callback(this, tickValue, index, ticks) {
                        return tickValue + ' %';
                    },
                    //y step
                    stepSize: 25,
                },

                //y label start at 0
                beginAtZero: true,
                min: 0,
                max: 100,
            },
        },
    };

    const getPercent = (val: number) => {
        return Number(((val / ((props.data.totalBay! * 60 * 60))) * 100).toFixed(1)) || 0;
    };

    const data: ChartData<'bar', number[], string> = {
        labels: _.range(1, 25).map((item) => item.toString()),
        datasets: [
            {
                label: '',
                data: [
                    getPercent(props.data.sumStayDuration_0_1!),
                    getPercent(props.data.sumStayDuration_1_2!),
                    getPercent(props.data.sumStayDuration_2_3!),
                    getPercent(props.data.sumStayDuration_3_4!),
                    getPercent(props.data.sumStayDuration_4_5!),
                    getPercent(props.data.sumStayDuration_5_6!),
                    getPercent(props.data.sumStayDuration_6_7!),
                    getPercent(props.data.sumStayDuration_7_8!),
                    getPercent(props.data.sumStayDuration_8_9!),
                    getPercent(props.data.sumStayDuration_9_10!),
                    getPercent(props.data.sumStayDuration_10_11!),
                    getPercent(props.data.sumStayDuration_11_12!),
                    getPercent(props.data.sumStayDuration_12_13!),
                    getPercent(props.data.sumStayDuration_13_14!),
                    getPercent(props.data.sumStayDuration_14_15!),
                    getPercent(props.data.sumStayDuration_15_16!),
                    getPercent(props.data.sumStayDuration_16_17!),
                    getPercent(props.data.sumStayDuration_17_18!),
                    getPercent(props.data.sumStayDuration_18_19!),
                    getPercent(props.data.sumStayDuration_19_20!),
                    getPercent(props.data.sumStayDuration_20_21!),
                    getPercent(props.data.sumStayDuration_21_22!),
                    getPercent(props.data.sumStayDuration_22_23!),
                    getPercent(props.data.sumStayDuration_23_0!),
                ],
                backgroundColor: [
                    COLOR_CAPACITY.from0To8,
                    COLOR_CAPACITY.from0To8,
                    COLOR_CAPACITY.from0To8,
                    COLOR_CAPACITY.from0To8,
                    COLOR_CAPACITY.from0To8,
                    COLOR_CAPACITY.from0To8,
                    COLOR_CAPACITY.from0To8,
                    COLOR_CAPACITY.from0To8,
                    COLOR_CAPACITY.from8To16,
                    COLOR_CAPACITY.from8To16,
                    COLOR_CAPACITY.from8To16,
                    COLOR_CAPACITY.from8To16,
                    COLOR_CAPACITY.from8To16,
                    COLOR_CAPACITY.from8To16,
                    COLOR_CAPACITY.from8To16,
                    COLOR_CAPACITY.from8To16,
                    COLOR_CAPACITY.from16To0,
                    COLOR_CAPACITY.from16To0,
                    COLOR_CAPACITY.from16To0,
                    COLOR_CAPACITY.from16To0,
                    COLOR_CAPACITY.from16To0,
                    COLOR_CAPACITY.from16To0,
                    COLOR_CAPACITY.from16To0,
                    COLOR_CAPACITY.from16To0,
                ],
                borderColor: 'rgba(75,192,192,1)',
                // barThickness: 20,
                //set width of bar column
                categoryPercentage: 1, // notice here
                barPercentage: 0.95,
            },
        ],
    };
    const { t } = useContext(LanguageContext);

    return (
        <Box sx={{ padding: 2 }}>
            <Stack spacing={2}>
                <Typography variant="h5">
                    {t('reportPage:text.hourlyCapacityAnalysisOn')} {formatDate.getLocalDay(props.data.inDate)} {'(%)'}
                </Typography>

                <Grid
                    item
                    xs={12}
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        // padding: '16px 16px 16px 0',
                        position: 'relative',
                        '& .chart-scroll-bar': {
                            '&::-webkit-scrollbar': {
                                height: '5px',
                            },

                            '&::-webkit-scrollbar-track': {
                                WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                height: '5px',
                                backgroundColor: color.primary,
                                borderRadius: '3px',
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                                cursor: 'pointer',
                                backgroundColor: color.darkPrimary,
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 10,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            variant="body1"
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                            fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                            mx={1}
                        >
                            <Box
                                sx={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    background: COLOR_CAPACITY.from0To8,
                                    mr: isMobileSM ? '3px' : '5px',
                                }}
                            ></Box>
                            {t('dashboard:text.0to8h')}
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                            fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                            mx={1}
                        >
                            <Box
                                sx={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    background: COLOR_CAPACITY.from8To16,
                                    mr: isMobileSM ? '3px' : '5px',
                                }}
                            ></Box>
                            {t('dashboard:text.8to16h')}
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                            fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                            mx={1}
                        >
                            <Box
                                sx={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    background: COLOR_CAPACITY.from16To0,
                                    mr: isMobileSM ? '3px' : '5px',
                                }}
                            ></Box>
                            {t('dashboard:text.16to24h')}
                        </Typography>
                    </Box>
                    <Box width={'100%'} style={{ minHeight: '250px' }}>
                        <ScrollContainer
                            className="container chart-scroll-bar"
                            style={{ height: '100%', width: '100%', paddingBottom: '5px' }}
                            hideScrollbars={false}
                        >
                            <Box height={'245px'} pb={4} width={'800px'}>
                                <Bar options={options} data={data} plugins={[ChartDataLabels]} />
                            </Box>
                        </ScrollContainer>
                    </Box>
                </Grid>
                <Stack direction={'row'} justifyContent="flex-end">
                    <Button variant="cancel" sx={{ minWidth: '150px' }} onClick={() => props.handleClose()}>
                        {t('action:cancel')}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}
