import { LoadingButton } from '@mui/lab';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AuthContext } from '../../../hooks/useAuth';
import { Role } from '../../../models/permission/Role';
import { RawUploadPermitStatus } from '../../../models/permits/ExcelRawPermit';
import Footer from '../../dashboardLayout/components/Footer';
import UpsertPermitFilter from '../addEditPermit/components/UpsertPermitFilter';
import UpsertPermitInfo from '../addEditPermit/components/UpsertPermitInfo';
import { UpsertPermitContext } from '../addEditPermit/hooks/useUpsertPermit';
import ManagedByUKPC from '../components/ManagedByUKPC';
import useUploadPermit from './hooks/useUploadPermits';

export default function UploadPermit() {
    const { t } = useContext(LanguageContext);
    const navigate = useNavigate();
    const {
        getStepComponent,
        handleNextStep,
        progressPercent,
        upsertPermitData,
        step,
        isLoading,
        listVRNChecking,
        setState,
        handleCancelUpload,
        filterState,
    } = useUploadPermit();
    const { account } = useContext(AuthContext);
    const validCount = listVRNChecking.reduce(
        (total, item) => (item.status === RawUploadPermitStatus.Valid ? (total += 1) : total),
        0
    );

    return (
        <UpsertPermitContext.Provider value={upsertPermitData}>
            <>
                <Stack direction={'row'} gap={1.5}>
                    <Typography variant="h3" fontWeight={400}>
                        {t('permits:title.uploadPermits')}
                    </Typography>
                    <ManagedByUKPC />
                </Stack>
                <Stack my={2}>
                    <MyBreadcrumbs />
                </Stack>
                <Grid container>
                    <Grid item xs={12} mt={2}>
                        <UpsertPermitFilter
                            setIsNoneTenants={upsertPermitData.setIsNoneTenants}
                            isFromStel={upsertPermitData.isFromStel}
                            handleClearOldParams={upsertPermitData.handleClearOldParams}
                            // setStelParams={upsertPermitData.setStelParams}
                            // stelParams={upsertPermitData.stelParams}
                        />
                    </Grid>
                </Grid>
                {filterState.zoneId && !!filterState.shopId && account.label !== Role.Tenant ? (
                    <Grid item xs={12} mt={3}>
                        <UpsertPermitInfo />
                    </Grid>
                ) : (
                    <></>
                )}

                {account.label === Role.Tenant && (
                    <Grid item xs={12} mt={3}>
                        <UpsertPermitInfo />
                    </Grid>
                )}

                {getStepComponent()}
                <Grid>
                    <Grid item container flexDirection={'row'} justifyContent="space-between" mt={2}>
                        <Button
                            variant="cancel"
                            sx={{ minWidth: '150px' }}
                            onClick={() => {
                                navigate(`/permits`);
                            }}
                        >
                            {t('action:cancel')}
                        </Button>

                        <Stack direction={'row'} gap={2}>
                            <Button
                                variant="outlined"
                                sx={{ minWidth: '150px', display: step === 2 ? 'block' : 'none' }}
                                onClick={() => {
                                    setState((p) => ({ ...p, step: 1 }));
                                    handleCancelUpload();
                                }}
                            >
                                {t('permits:upload.uploadAgain')}
                            </Button>
                            <LoadingButton
                                loading={isLoading}
                                startIcon={<></>}
                                loadingPosition="start"
                                variant="contained"
                                disabled={step === 1 ? progressPercent !== 100 : validCount === 0}
                                sx={{ minWidth: '150px' }}
                                type="submit"
                                onClick={handleNextStep}
                            >
                                {step === 2 ? `${t('action:save')} (${validCount})` : t('action:next')}
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </>
        </UpsertPermitContext.Provider>
    );
}
