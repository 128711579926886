import { AzureMediaController } from './azureMediaController';
import { AccountController } from './AccountController';
import { AnprController } from './AnprController';
import { axiosCreator } from './axiosConfig';
import { appConfig } from '../config/AppConfig';
import { CompaniesController } from './CompaniesController';
import { PermitController } from './permitsController/permitsController';
import { LocationController } from './LocationController';
import { ShopController } from './ShopController';
import { UserController } from './UserController';
import { ZoneController } from './ZoneController';
import { StatisticPCNController } from './StatisticPCNController';
import { RegionController } from './RegionController';
import { AnprDashboardDownload } from './AnprDashboardDownload';
import { PCNDashboardDownloadController } from './PCNDashboardDownloadController';
import { LocalStore } from '../helpers/localStorage';
import toast from 'react-hot-toast';
import { ReportBugController } from './ReportBugController';
import { FeedBackController } from './FeedbackController';
import { IHUB_LANGUAGE, WebLanguage } from '../constants';

const apiUrl = appConfig.gateway.clientPortalAPI;
const apiClientPortal = apiUrl + '/ClientPortal';
const azureMediaUrl = appConfig.gateway.apiAzureMedia;
const language = LocalStore.getValue(IHUB_LANGUAGE) || WebLanguage.en;

export const axiosClient = axiosCreator({
    // url: apiUrl.authen,
    url: apiUrl,
    onError: (err: any) => {
        // if (err.status == 401) {
        //     window.location.href = '/login';
        // }
        // if (err.status == 404) {
        //     window.location.href = '/';
        // }
        if (err.status === 500) {
            // window.location.href = '/';
            toast.error(language === 'de' ? 'Interner Serverfehler' : 'Internal server error');
        }
    },
});

export const accountController = new AccountController(apiUrl, axiosClient);
export const anprController = new AnprController(apiClientPortal, axiosClient);
export const companiesController = new CompaniesController(apiClientPortal, axiosClient);
export const locationController = new LocationController(apiClientPortal, axiosClient);
export const regionController = new RegionController(apiClientPortal, axiosClient);
export const shopController = new ShopController(apiClientPortal, axiosClient);
export const userController = new UserController(apiClientPortal, axiosClient);
export const zoneController = new ZoneController(apiClientPortal, axiosClient);
export const azureMediaController = new AzureMediaController(azureMediaUrl, axiosClient);
export const permitController = new PermitController(apiClientPortal, axiosClient);
export const statisticPCNController = new StatisticPCNController(apiClientPortal, axiosClient);
export const anprDashboardDownload = new AnprDashboardDownload(apiClientPortal, axiosClient);
export const pcnDashboardDownloadController = new PCNDashboardDownloadController(apiClientPortal, axiosClient);
export const reportBugController = new ReportBugController(apiUrl, axiosClient);
export const feedbackController = new FeedBackController(apiClientPortal, axiosClient);
