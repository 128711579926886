import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosClient } from '../controllers';
import { LocalStore } from '../helpers/localStorage';
import '../i18n';
import { IHUB_LANGUAGE, WebLanguage } from '../constants';

interface State {
    default: string;
}

export const getDefaultLanguage = () => {
    const language = LocalStore.getValue(IHUB_LANGUAGE);

    if (!language || (language !== WebLanguage.en && language !== WebLanguage.de)) {
        LocalStore.setValue(IHUB_LANGUAGE, WebLanguage.en);
        return WebLanguage.en;
    }

    return language;
};

export default function UseLanguage() {
    const [state, setState] = useState<State>({ default: getDefaultLanguage() });
    const { t, i18n } = useTranslation();
    //set language to header
    const language = LocalStore.getValue(IHUB_LANGUAGE) || WebLanguage.en;
    axiosClient.defaults.headers['Accept-Language'] = language;

    const onChangeLanguage = (value: WebLanguage) => {
        LocalStore.setValue(IHUB_LANGUAGE, value);
        window.location.reload();
    };

    useEffect(() => {
        i18n.changeLanguage(state.default !== WebLanguage.en ? 'ge' : state.default);
    }, []);

    return { ...state, ...i18n, t, onChangeLanguage, language, setState };
}

export const LanguageContext = React.createContext<ReturnType<typeof UseLanguage>>({} as any);
