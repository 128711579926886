import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import BarStackedChart from '../../../components/chart/BarStackedChart';
import { DoughnutChart } from '../../../components/chart/DoughnutChart';
import LoadingCircular from '../../../components/LoadingCircular';
import color from '../../../config/Colors';
import { COLOR_DAILY_VISITS } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';
import useMedia from '../../../hooks/useMedia';
import { ChartShowType } from '../../../models/ChartShowType';
import { EnumPCNsByServiceType, PCNsByServiceTypeStatistic } from '../../../models/PCNsByServiceTypeStatistic';
import { ReportType } from '../../../models/ReportType';
import { GridBorderRadius, GridWithGray100, TypographyCenter } from '../styled';
import { ChartSubDescription } from './ActiveLTBooking';
import ChartTitleContent from './ChartTitleContent';

type Props = {
    chartShowType?: ChartShowType;
    pcnsByServiceTypeStatistic: PCNsByServiceTypeStatistic;
    reportType?: ReportType;
};

const ColorANPR = '#17A2B8';
const ColorIticketLite = '#CC0066';
const LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE = [
    color.priText,
    ColorIticketLite,
    color.primary,
    ColorANPR,
    COLOR_DAILY_VISITS.diesel!,
];

export default function PCNsByServiceType(props: Props) {
    const { t } = useContext(LanguageContext);
    const { isMobileSM } = useMedia();
    const { chartShowType, pcnsByServiceTypeStatistic, reportType } = props;

    const dataLength =
        (props.pcnsByServiceTypeStatistic &&
            props.pcnsByServiceTypeStatistic.items &&
            props.pcnsByServiceTypeStatistic.items.length) ||
        0;

    return props.pcnsByServiceTypeStatistic.items ? (
        <GridWithGray100 container item md={12} sm={12} xs={12} borderRadius={'10px'}>
            <GridBorderRadius container item lg={8} xs={12} gap={2} border={`1px solid ${color.grey200} !important`}>
                <ChartTitleContent
                    key={'14'}
                    title={t('dashboard:title.dailyPCNsIssuedByServiceType')}
                    desc={t('dashboard:text.dailyPCNCountsByServiceType')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    reportType={reportType}
                    chartShowType={chartShowType}
                />

                <Grid
                    item
                    xs={12}
                    sx={{
                        '& .chart-scroll-bar': {
                            '&::-webkit-scrollbar': {
                                height: '5px',
                            },

                            '&::-webkit-scrollbar-track': {
                                WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                height: '5px',
                                backgroundColor: color.primary,
                                borderRadius: '3px',
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                                cursor: 'pointer',
                                backgroundColor: color.darkPrimary,
                            },
                            '&::-webkit-scrollbar:vertical': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <Box position={'relative'} width={'100%'} height={'100%'}>
                        <ScrollContainer
                            className="container chart-scroll-bar"
                            style={{ height: '100%', width: '100%', paddingBottom: '20px', position: 'relative' }}
                            hideScrollbars={false}
                        >
                            <Box
                                width={{
                                    lg: dataLength > 15 ? `${dataLength * 70}px` : '100%',
                                    md: dataLength > 10 ? `${dataLength * 70}px` : '100%',
                                    sm: dataLength > 7 ? `${dataLength * 70}px` : '100%',
                                    xs: dataLength > 4 ? `${dataLength * 70}px` : '100%',
                                }}
                                height={'300px'}
                                pb={4}
                                position="relative"
                            >
                                <BarStackedChart
                                    chartColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE}
                                    chartData={pcnsByServiceTypeStatistic.items}
                                    dataKeys={['qtyOfPOs', 'qtyOfiTicketLite', 'qtyOfEcam', 'qtyOfANPR', 'qtyOfSTS']}
                                    dataKeysDate={'inDate'}
                                    label={[
                                        EnumPCNsByServiceType.ParkingOperatives,
                                        EnumPCNsByServiceType.iTicketLite,
                                        EnumPCNsByServiceType.eCam,
                                        EnumPCNsByServiceType.ANPR,
                                        EnumPCNsByServiceType.STS,
                                    ]}
                                />
                            </Box>
                        </ScrollContainer>
                        <Stack
                            width={'100%'}
                            position={'absolute'}
                            bottom={{ xs: 10, sm: 20 }}
                            justifyContent={'center'}
                            flexDirection={'row'}
                            flexWrap={'wrap'}
                        >
                            <ChartSubDescription
                                backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[0]}
                                isMobileSM={isMobileSM}
                                text={EnumPCNsByServiceType.ParkingOperatives}
                            />
                            <ChartSubDescription
                                backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[1]}
                                isMobileSM={isMobileSM}
                                text={EnumPCNsByServiceType.iTicketLite}
                            />
                            <ChartSubDescription
                                backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[2]}
                                isMobileSM={isMobileSM}
                                text={EnumPCNsByServiceType.eCam}
                            />
                            <ChartSubDescription
                                backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[3]}
                                isMobileSM={isMobileSM}
                                text={EnumPCNsByServiceType.ANPR}
                            />
                            <ChartSubDescription
                                backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[4]}
                                isMobileSM={isMobileSM}
                                text={EnumPCNsByServiceType.STS}
                            />
                        </Stack>
                    </Box>
                </Grid>
            </GridBorderRadius>

            <Grid
                item
                container
                lg={4}
                xs={12}
                sx={{ height: 'max-content', pl: { lg: 2, xs: 0 }, mt: { lg: 0, xs: 2 } }}
            >
                <GridBorderRadius item container xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t('dashboard:title.totalPCNsByServiceType')}</Typography>
                        <Typography color={color.priText} variant="body2" minHeight={'18px'}>
                            {t('dashboard:text.totalPCNsIssuedCategorizedByServiceType')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DoughnutChart
                            chartShowType={ChartShowType.report}
                            statisticItems={{
                                items: [
                                    {
                                        amount: pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfPOs,
                                        label: EnumPCNsByServiceType.ParkingOperatives,
                                    },
                                    // t('dashboard:text.parkingOperatives')
                                    {
                                        amount: pcnsByServiceTypeStatistic.totalPCNForEachServiceType
                                            ?.qtyTotalOfiTicketLite,
                                        label: EnumPCNsByServiceType.iTicketLite,
                                    },
                                    {
                                        amount: pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfEcam,
                                        label: EnumPCNsByServiceType.eCam,
                                    },
                                    {
                                        amount: pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfANPR,
                                        label: EnumPCNsByServiceType.ANPR,
                                    },
                                    {
                                        amount: pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfSTS,
                                        label: EnumPCNsByServiceType.STS,
                                    },
                                ],
                            }}
                            listColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE}
                        />
                    </Grid>

                    <TypographyCenter mt={4} mb={2} width={'100%'} bgcolor={color.lightPrimary}>
                        {t('dashboard:title.totalPCNs')}:{' '}
                        <Typography variant="caption" fontSize={{ xs: '18px', sm: '24px' }} fontWeight={500}>
                            {pcnsByServiceTypeStatistic.totalPCNs}
                        </Typography>
                    </TypographyCenter>

                    <Stack justifyContent="flex-start" flex={1}>
                        <Divider
                            sx={{
                                mb: '8px !important',
                                borderColor: color.grey200,
                            }}
                        />
                        <SubDescriptionColumn
                            backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[0]}
                            isMobileSM={isMobileSM}
                            text={EnumPCNsByServiceType.ParkingOperatives}
                            totalPcn={pcnsByServiceTypeStatistic.totalPCNs}
                            qtyPcn={pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfPOs}
                        />
                        <SubDescriptionColumn
                            backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[1]}
                            isMobileSM={isMobileSM}
                            text={EnumPCNsByServiceType.iTicketLite}
                            totalPcn={pcnsByServiceTypeStatistic.totalPCNs}
                            qtyPcn={pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfiTicketLite}
                        />
                        <SubDescriptionColumn
                            backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[2]}
                            isMobileSM={isMobileSM}
                            text={EnumPCNsByServiceType.eCam}
                            totalPcn={pcnsByServiceTypeStatistic.totalPCNs}
                            qtyPcn={pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfEcam}
                        />
                        <SubDescriptionColumn
                            backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[3]}
                            isMobileSM={isMobileSM}
                            text={EnumPCNsByServiceType.ANPR}
                            totalPcn={pcnsByServiceTypeStatistic.totalPCNs}
                            qtyPcn={pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfANPR}
                        />
                        <SubDescriptionColumn
                            backgroundColor={LIST_COLOR_CHART_PCNs_BY_SERVICE_TYPE[4]}
                            isMobileSM={isMobileSM}
                            text={EnumPCNsByServiceType.STS}
                            totalPcn={pcnsByServiceTypeStatistic.totalPCNs}
                            qtyPcn={pcnsByServiceTypeStatistic.totalPCNForEachServiceType?.qtyTotalOfSTS}
                        />
                    </Stack>
                </GridBorderRadius>
            </Grid>
        </GridWithGray100>
    ) : (
        <LoadingCircular />
    );
}

const SubDescriptionColumn = (props: {
    isMobileSM: boolean;
    backgroundColor: string;
    text: string;
    totalPcn: number;
    qtyPcn: number;
}) => {
    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography
                variant="body1"
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '3px' }}
                fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
            >
                <Box
                    sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        background: props.backgroundColor,
                        mr: props.isMobileSM ? '2px' : '5px',
                    }}
                ></Box>
                {props.text} {props.qtyPcn != 0 ? `(${((props.qtyPcn / props.totalPcn) * 100).toFixed(1)}%)` : '(0%)'}
            </Typography>
            <Typography fontWeight={500}>{props.qtyPcn}</Typography>
        </Stack>
    );
};
