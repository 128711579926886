import { Box, Grid, Pagination, Stack, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import MyTable from '../../../../MyTable';

import { ReportType } from '../../../../../models/ReportType';
import Zone from '../../../../../models/Zone';
import MyModal from '../../../../../components/modal/MyModal';
import useModal from '../../../../../components/modal/useModal';
import UpdatePermitsModal from '../UpdatePermitsModal';
import { CompaniesContext } from '../../../../../hooks/useCompanies';
import NoDataToDisplay from '../../../../../components/NoDataToDisplay';
import color from '../../../../../config/Colors';
import { LocationsContext } from '../../../../../hooks/useLocations';
import TableZones from './TableZones/TableZones';
import QuestionCircle from '../../../../../assets/svg/QuestionCircleIcon';

export default function ZonesTab() {
    const { t } = useContext(LanguageContext);
    const updatePermitModal = useModal();
    const [zone, setZone] = useState<Zone>({} as any);
    const { zoneDataState, getLocationDetail } = useContext(LocationsContext);

    const { zonesSelect } = zoneDataState;
    const handleOpenPermitModal = (zone: Zone) => {
        setZone(zone);
        updatePermitModal.handleOpen();
    };

    useEffect(() => {
        getLocationDetail();
    }, []);

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        // setState((prev) => ({ ...prev, filter: { ...prev.filter, page: value } }));
    };

    return (
        <Grid item container xs={12}>
            <MyModal
                key={1}
                open={updatePermitModal.open}
                handleOpen={updatePermitModal.handleOpen}
                handleClose={updatePermitModal.handleClose}
                modalContent={<UpdatePermitsModal zone={zone} handleClose={updatePermitModal.handleClose} />}
            />

            {zonesSelect && zonesSelect.length > 0 ? (
                <>
                    <MyTable
                        tableHead={[
                            {
                                label: t('companies:text.zoneName'),
                                alight: 'left',
                            },
                            {
                                label: t('companies:text.type'),
                                alight: 'left',
                            },
                            {
                                label: t('companies:text.serviceType'),
                                alight: 'center',
                            },
                            {
                                label: t('companies:text.totalBays'),
                                alight: 'center',
                            },
                            {
                                label: (
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={1}>
                                        {t('companies:text.totalBaysCount')}
                                        <Tooltip
                                            sx={{
                                                '&:hover': {
                                                    '& .employeeIcon': {
                                                        stroke: color.secondary,
                                                        transition: '0.3s',
                                                    },
                                                },
                                            }}
                                            placement="top"
                                            title={
                                                'Please use this bay count feature to adjust the capacity analysis report to reflect the amount of available bays on that particular zone'
                                            }
                                        >
                                            <Stack alignItems={'center'}>
                                                <QuestionCircle style={{ cursor: 'pointer' }} />
                                            </Stack>
                                        </Tooltip>
                                    </Stack>
                                ),
                                alight: 'center',
                            },
                            {
                                label: <></>,
                                alight: 'center',
                            },
                        ]}
                        reportType={ReportType.dailyVisit}
                        tableRow={<TableZones handleEdit={handleOpenPermitModal} />}
                    />
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                        <Pagination
                            count={1}
                            page={1}
                            onChange={handleChangePagination}
                            variant="outlined"
                            shape="rounded"
                            sx={{ mt: 3, mb: '40px' }}
                        />{' '}
                    </Stack>
                </>
            ) : (
                <Box padding={'16px'} width={'100%'}>
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                </Box>
            )}
        </Grid>
    );
}
