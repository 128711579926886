import { Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseOutline } from 'react-icons/io5';
import BaseListRender from '../../components/BaseListRender';
import color from '../../config/Colors';
import useDatePicker from '../../hooks/useDatePicker';
import { Pagination } from '../../models/BaseFilter';
import { Feedback } from '../../models/feedback/Feedback';
import IconSearch from '../companyLayout/components/svg/IconSearch';
import FeedBackItem from './components/FeedBackItem';
import { feedbackController } from '../../controllers';
import useDrawer, { DrawerContext } from '../dashboardLayout/useDrawer';
import { FilterFeedbackProps } from '../../controllers/FeedbackController';
import { endOfDay, startOfDay } from 'date-fns';
import { ConvertToGMT0, ConvertToGMT0Time } from '../../helpers';
import { useSearchParams } from 'react-router-dom';

type Props = {};

export enum ParamsFilterFeedBack {
    from = 'fromFb',
    to = 'toFb',
    page = 'pageFb',
    search = 'searchFb',
}

const FeedbackPage = (props: Props) => {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const params = {
        searchValue: searchParams.get(ParamsFilterFeedBack.search),
        from: searchParams.get(ParamsFilterFeedBack.from),
        to: searchParams.get(ParamsFilterFeedBack.to),
        page: searchParams.get(ParamsFilterFeedBack.page),
    };

    const { setActiveItem } = useContext(DrawerContext);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [filters, setFilters] = useState<FilterFeedbackProps>({
        from: !!params.from ? new Date(params.from) : undefined,
        to: !!params.to ? new Date(params.to) : undefined,
        search: !!params.searchValue ? params.searchValue : undefined,
        page: !!params.page ? Number(params.page) : undefined,
    });
    const setPartialFilter = (partial: Partial<FilterFeedbackProps>) => setFilters((prev) => ({ ...prev, ...partial }));
    const [isFilterLoading, setIsFilterLoading] = useState(true);

    const [pagingFeedback, setPagingFeedback] = useState<Pagination<Feedback>>({
        page: 1,
        pageSize: 10,
        data: [],
        total: 0,
        totalPage: 0,
    });

    useEffect(() => {
        setActiveItem(8);
    }, []);

    useEffect(() => {
        setIsFilterLoading(true);
        feedbackController
            .filter({ ...filters, from: ConvertToGMT0(filters.from), to: ConvertToGMT0(filters.to) })
            .then((res) => {
                setPagingFeedback(res);
            })
            .finally(() => setIsFilterLoading(false));

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [filters]);

    const onChangeSearch = (e: any) => {
        setPartialFilter({ search: e.target.value, page: 1 });
    };

    const onClickClearSearch = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            setPartialFilter({ search: undefined });
        }
    };

    const { RenderDatePicker } = useDatePicker({
        startDate: filters.from,
        endDate: filters.to,
        onChangeTime(e) {
            setPartialFilter({ from: startOfDay(e[0]), to: endOfDay(e[1]), page: 1 });
        },
    });

    const handleResetFilter = () => {
        onClickClearSearch();
        setPartialFilter({ page: 1, from: undefined, to: undefined });
    };

    useEffect(() => {
        handleChangeFilterToParams();
    }, [filters, searchParams]);

    const handleChangeFilterToParams = () => {
        const newParams = new URLSearchParams({});

        if (filters.from) newParams.append(ParamsFilterFeedBack.from, ConvertToGMT0Time(filters.from).toISOString());
        if (filters.to) newParams.append(ParamsFilterFeedBack.to, ConvertToGMT0Time(filters.to).toISOString());
        if (filters.page && filters.page !== 1) newParams.append(ParamsFilterFeedBack.page, filters.page.toString());
        if (filters.search && filters.search !== '') newParams.append(ParamsFilterFeedBack.search, filters.search);

        setSearchParams(newParams);
    };

    return (
        <Stack direction={'column'} gap={3}>
            <Stack
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                justifyContent={'space-between'}
            >
                <Typography variant="h3" fontWeight={400}>
                    Feedback
                </Typography>
                <Stack width={'100%'} justifyContent="end">
                    <Grid
                        item
                        display={'flex'}
                        flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                        spacing={1}
                        justifyContent={'end'}
                        container
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md="auto"
                            sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: color.grey300 } }}
                        >
                            <TextField
                                inputRef={inputRef}
                                fullWidth
                                placeholder={t('companies:input.searchByName')}
                                onChange={_.debounce((value) => onChangeSearch(value), 1000)}
                                inputProps={{
                                    maxLength: 75,
                                }}
                                // sx={{ '& .MuiOutlinedInput-root': { height: '37px' } }}
                                defaultValue={''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                '& .clear-content:hover': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            {/* <IoCloseOutline
                                                className="clear-content"
                                                fontSize={'20px'}
                                                onClick={onClickClearSearch}
                                                style={{ display: inputRef.current?.value !== '' ? 'block' : 'none' }}
                                            /> */}

                                            <IconButton>
                                                <IconSearch />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md="auto">
                            {RenderDatePicker}
                        </Grid>

                        <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Button variant="cancel" sx={{ minWidth: '140px' }} onClick={handleResetFilter}>
                                {'Reset filter'}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>

            <Stack gap={2}>
                <Typography textAlign={'end'} sx={{ color: color.grey600 }}>
                    Total: {pagingFeedback.total}
                </Typography>

                <BaseListRender
                    styleContainer={{ mb: 5 }}
                    styleList={{ gap: 2 }}
                    paging={pagingFeedback}
                    renderedItem={(feedBack) => <FeedBackItem feedbackItem={feedBack} />}
                    isLoading={isFilterLoading}
                    setPage={(page) => {
                        setPartialFilter({ page });
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default FeedbackPage;
