/* eslint-disable no-useless-concat */
import { Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';
import color from '../../../../../../config/Colors';
import { capitalizeFirstLetter } from '../../../../../../helpers/UppercaseFirstLetter';
import { Permit } from '../../../../../../models/permits/Permit';
import { UpsertPermitContext } from '../../../hooks/useUpsertPermit';

type Props = {
    permit: Permit;
    isValid: boolean;
};
export default function UpsertPermitItemTitleEdit(props: Props) {
    const { t, clientInfo } = useContext(UpsertPermitContext);
    const { created } = props.permit;

    const getStringTimeAndDate = (date: Date) => {
        const currentDate = moment(date);
        return {
            time: currentDate.format('HH:mm'),
            date: currentDate.format('DD/MM/YY'),
        };
    };

    return (
        <Grid
            container
            display={'flex'}
            padding={'15px 15px'}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={'space-between'}
        >
            <Grid item>
                <Typography variant="body1" fontWeight={500} color={color.success}>
                    {t('permits:title.permitsInformation')}
                </Typography>
            </Grid>
            {clientInfo && (
                <Grid item>
                    {' '}
                    <Typography component="div">
                        <Typography color={color.grey600} fontWeight={500} component="span">
                            {t('permits:text.CreatedBy')}
                            {` `}
                        </Typography>
                        <Typography color={color.grey600} fontWeight={700} component="span">
                            {`${capitalizeFirstLetter(clientInfo.firstName! + ' ' + clientInfo.lastName!)}`}
                            {` `}
                        </Typography>
                        <Typography color={color.grey600} fontWeight={500} component="span">
                            {t('permits:text.atOn', { time: `${getStringTimeAndDate(created!).time}` })}
                            {' ' + `${getStringTimeAndDate(created!).date}`}
                        </Typography>
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}
