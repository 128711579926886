import { Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import color from '../../../config/Colors';
import { ukpcLogo } from '../../../config/ImageFromEnv';

export default function ManagedByUKPC() {
    const isSmallScreen = useMediaQuery('(max-width: 1200px)');
    return (
        <Stack gap={1}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography sx={{ fontSize: '12px' }} color={color.secondary} fontStyle={'italic'}>
                    managed by
                </Typography>
                <CustomWidthTooltip
                    arrow
                    placement={isSmallScreen ? 'bottom' : 'right-end'}
                    title={
                        <Stack direction={'column'} gap={'5px'}>
                            <Typography color={'white'}>
                                Welcome to our new and improved iPermit system, courtesy of Intelli-Park, managed by
                                UKPC.
                            </Typography>

                            <Typography color={'white'}>
                                You may be unfamiliar with our new parent brand, Intelli-Park, but rest assured you will
                                still be getting the same trusted UKPC experience that you are used to.
                            </Typography>

                            <Typography color={'white'}>
                                Intelli-Park is all about intelligent solutions and we are always looking to use data
                                and technology to create value for our clients and to make their customers’ lives
                                simpler.
                            </Typography>
                            <Typography color={'white'}>
                                The UKPC brand will still exist to provide our exceptional parking enforcement services,
                                but Intelli-Park will serve to usher in a wave of highly innovative, technological
                                solutions that will allow for a seamless customer experience.
                            </Typography>
                        </Stack>
                    }
                >
                    <img src={ukpcLogo} width={'75px'} alt="logo" />
                </CustomWidthTooltip>
            </Stack>
        </Stack>
    );
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 700,
    },
});
