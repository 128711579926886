export interface PCNsByServiceTypeStatistic {
    items: Item[];
    totalPCNs: number;
    totalPCNForEachServiceType: TotalPCNForEachServiceType;
}

interface Item {
    inDate: Date;
    qtyOfPOs: number;
    qtyOfiTicketLite: number;
    qtyOfEcam: number;
    qtyOfANPR: number;
    qtyOfSTS: number;
}

interface TotalPCNForEachServiceType {
    qtyTotalOfPOs: number;
    qtyTotalOfiTicketLite: number;
    qtyTotalOfEcam: number;
    qtyTotalOfANPR: number;
    qtyTotalOfSTS: number;
}

export enum EnumPCNsByServiceType {
    ParkingOperatives = 'Parking Operatives',
    iTicketLite = 'iTicket Lite',
    eCam = 'Ecam',
    ANPR = 'ANPR',
    STS = 'STS',
}
