import { Box, Stack, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GridCard } from '../../../../components/GridCard';
import color from '../../../../config/Colors';
import IconButton from '@mui/material/IconButton';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { formatDate } from '../../../../helpers/moment';
import IconEdit from './IconEdit';
import useMedia from '../../../../hooks/useMedia';
import { Employee } from '../../../../models/Employee';
import useDialog from './EmpDialog';
import { userController } from '../../../../controllers';
import { EmployeeContext } from '../../../../hooks/useEmployee';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';

type Props = {
    employee: Employee;
};

export default function EmployeeItem({ employee }: Props) {
    const { t } = useContext(LanguageContext);
    const navigate = useNavigate();
    const { isMobileSM } = useMedia();
    const employeeContext = useContext(EmployeeContext);

    const empDialog = useDialog({
        handleAgree: () => {
            userController.get(employee.id.toString()).then((res) => {
                userController.delete(res.id!.toString()).then((res) => {
                    toast.success(t('notification:successfully'));
                    employeeContext.setState((prev) => ({ ...prev, setRefreshData: 1 }));
                });
            });
        },
    });

    return (
        <GridCard item container xs={12}>
            {empDialog.RenderDialog}
            <Stack sx={{ width: '100%' }} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                <Box>
                    <Typography
                        sx={{
                            '&:hover': {
                                color: color.primary,
                                textDecorationLine: 'underline',
                                transition: '0.3s',
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => navigate(`update/${employee.id}`)}
                        variant="h5"
                    >
                        {employee.firstName + ' ' + employee.lastName}
                    </Typography>
                </Box>
                <Box>
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                        <Stack
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                background: color.grey200,
                                borderRadius: '50%',
                                border: '8px solid',
                                borderColor: color.grey100,
                            }}
                        >
                            <Tooltip
                                sx={{
                                    '&:hover': {
                                        '& .iconLocation': {
                                            fill: 'orange',
                                            transition: '0.3s',
                                        },
                                    },
                                    backgroundColor: color.danger,
                                }}
                                title={t('action:edit')}
                                placement="top"
                            >
                                <Link to={`update/${employee.id}`}>
                                    <IconButton
                                        // onClick={() => navigate(`update/${employee.id}`)}
                                        aria-label="fingerprint"
                                        color="success"
                                    >
                                        <IconEdit />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Stack>

                        {/* <Stack
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                background: color.grey200,
                                borderRadius: '50%',
                                border: '8px solid',
                                borderColor: color.grey100,
                                '& .MuiTooltip-popper': {
                                    background: color.danger,
                                },
                            }}
                        >
                            <DangerTooltip title={t('action:delete')} placement="top">
                                <IconButton
                                    onClick={empDialog.handleClickOpen}
                                    aria-label="fingerprint"
                                    color="success"
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                        '&:hover': {
                                            '& .svg-icon-stroke': {
                                                stroke: color.danger,
                                                transition: '0.3s',
                                            },
                                        },
                                    }}
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </DangerTooltip>
                        </Stack> */}
                    </Stack>
                </Box>
            </Stack>
            <Typography sx={{ width: '100%' }} variant="body1">
                {t('companies:text.email')}: {employee.email}
            </Typography>
            <Stack direction={!isMobileSM ? 'row' : 'column'} mt={1}>
                <Typography sx={{ mr: 1 }} variant="body1">
                    {t('companies:text.phone')}: {employee.phoneNumber}
                </Typography>
                <Typography
                    variant="body1"
                    borderLeft={isMobileSM ? `none` : `1px solid ${'#85858A'}`}
                    pl={isMobileSM ? `` : `5px`}
                    mt={isMobileSM ? `8px` : ``}
                >
                    {t('companies:text.createAt')}: {formatDate.getLocalDate(employee.created)}
                </Typography>
            </Stack>
        </GridCard>
    );
}

const DangerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color.danger,
        color: color.white,
    },
}));
