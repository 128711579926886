/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { useLocation, useSearchParams } from 'react-router-dom';
import FilterMobileIcon from '../../../assets/svg/FilterMobileIcon';
import { SelectItem } from '../../../components/MySelect';
import IAutoCompleteRF from '../../../components/autoComplete/IAutoComplete2';
import IMultiSelectWithCheckBoxRF from '../../../components/autoComplete/IMultiSelectWithCheckBoxRF';
import useModal from '../../../components/modal/useModal';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import {
    companiesController,
    locationController,
    permitController,
    regionController,
    shopController,
    zoneController,
} from '../../../controllers';
import { FilterZoneProps } from '../../../controllers/ZoneController';
import { AuthContext } from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';
import { LoadingFilter, _initLoadingFilter } from '../../../hooks/useFilter';
import useMedia from '../../../hooks/useMedia';
import { Pagination } from '../../../models/BaseFilter';
import { Company } from '../../../models/Company';
import { LocationWithDetail } from '../../../models/Location';
import { Region } from '../../../models/Region';
import Zone from '../../../models/Zone';
import { AdvancedFilterOptions, StatusFilter } from '../../../models/permits/FilterPermits';
import { Permit } from '../../../models/permits/Permit';
import { RecurringType } from '../../../models/permits/PermitRecurring';
import { Tenant, TenantWithAllocate } from '../../../models/tenant/Tenant';
import IconSearch from '../../companyLayout/components/svg/IconSearch';
import AdvancedFilterLarge from '../components/AdvancedFilterLarge';
import FilterModalContent from '../components/FilterModalContent';
import ModalFilterPermits from '../components/ModalFilterPermits';
import { endOfDay, startOfDay } from 'date-fns';
import _ from 'lodash';
import { ConvertToGMT0Time } from '../../../helpers';
import { fontInputAddPermits } from '../../../config/Fonts';
import IAutoCompleteLocation from '../../../components/autoComplete/IAutoCompleteLocation';

export interface StateFilter {
    permitPagination: Pagination<Permit>;
    isLoading: boolean;
}

export interface AdvancedFilterItem {
    id: number;
    name: string;
    value: string | number | Date | null;
    isDisable: boolean;
    isShowing: boolean;
}
export type AdvancedName = 'tenant' | 'permitType' | 'status' | 'validFrom' | 'validTo' | 'recurringType';

export interface DataFilterAll {
    locations: LocationWithDetail[];
    companies: Company[];
    regions: Region[];
}
export interface DataPermitsState extends DataFilterAll {
    zones: Zone[];
    permitTypes: SelectItem[];
    tenants: TenantWithAllocate[];
}

const _initDataPermits: DataPermitsState = {
    companies: [],
    locations: [],
    permitTypes: [],
    regions: [],
    tenants: [],
    zones: [],
};

export interface FilterStatePermitId {
    companyId?: number;
    regionId?: number;
    locationId?: number;
    shopId?: number;
    zoneIds?: number[];
    type?: string;
    status?: StatusFilter;
    validFrom?: Date;
    validTo?: Date;
    recurringType?: RecurringType | null;
    page?: number;
    searchValue?: string;
}

export enum ParamsFilterPermit {
    company = 'companyPId',
    region = 'regionPId',
    location = 'locationPId',
    shop = 'shopPId',
    zones = 'zonePIds',
    type = 'type',
    status = 'status',
    validFrom = 'validFrom',
    validTo = 'validTo',
    recurringType = 'recurringType',
    page = 'page',
    searchValue = 'searchValue',
}

const _initAdvancedFilter: AdvancedFilterOptions = {
    isShowFrom: false,
    isShowPermitType: false,
    isShowStatus: false,
    isShowTo: false,
    isShowRecurringType: false,
};

export type SelectPermitField = 'location' | 'company';

export default function usePermitsFilter() {
    const { t } = useContext(LanguageContext);

    const [searchParams, setSearchParams] = useSearchParams();

    const params = {
        companyId: searchParams.get(ParamsFilterPermit.company),
        locationId: searchParams.get(ParamsFilterPermit.location),
        regionId: searchParams.get(ParamsFilterPermit.region),
        shopId: searchParams.get(ParamsFilterPermit.shop),
        zoneIds: searchParams.get(ParamsFilterPermit.zones),
        type: searchParams.get(ParamsFilterPermit.type),
        status: searchParams.get(ParamsFilterPermit.status),
        from: searchParams.get(ParamsFilterPermit.validFrom),
        to: searchParams.get(ParamsFilterPermit.validTo),
        recurringType: searchParams.get(ParamsFilterPermit.recurringType),
        page: searchParams.get(ParamsFilterPermit.page),
        searchValue: searchParams.get(ParamsFilterPermit.searchValue),
    };

    const { account, isGoldRole, isSilverRole, isBronzeRole } = useContext(AuthContext);
    const { isMobileSM } = useMedia();
    const stateModalFilter = useModal();

    const [state, setState] = useState<StateFilter>({
        permitPagination: {
            data: [],
            page: 1,
            pageSize: 6,
            total: 0,
            totalPage: 0,
        },
        isLoading: false,
    });

    const [selectField, setSelectField] = useState<SelectPermitField>();
    const location = useLocation();
    const isPermitsPage = location.pathname === '/permits';

    const [loading, setLoading] = useState<LoadingFilter>(_initLoadingFilter);
    const setPartialLoading = (partial: Partial<LoadingFilter>) => setLoading((prev) => ({ ...prev, ...partial }));

    const [dataState, setDataState] = useState<DataPermitsState>(_initDataPermits);
    const setPartialData = (p: Partial<DataPermitsState>) => setDataState((prev) => ({ ...prev, ...p }));

    const [filterState, setFilterState] = useState<FilterStatePermitId>({
        companyId: !!params.companyId ? Number(params.companyId) : undefined,
        locationId: !!params.locationId ? Number(params.locationId) : undefined,
        regionId: !!params.regionId ? Number(params.regionId) : undefined,
        shopId: isBronzeRole() ? account.id! : !!params.shopId ? Number(params.shopId) : undefined,
        zoneIds: !!params.zoneIds ? params.zoneIds.split(',').map(Number) : [],
        type: !!params.type ? params.type : '',
        status: !!params.status ? Number(params.status) : StatusFilter.All,
        recurringType: !!params.recurringType ? (Number(params.recurringType) as RecurringType) : null,
        searchValue: !!params.searchValue ? params.searchValue : '',
        page: !!params.page ? Number(params.page) : undefined,
        validFrom: !!params.from ? new Date(params.from) : undefined,
        validTo: !!params.to ? new Date(params.to) : undefined,
    });
    const setPartialFilter = (p: Partial<FilterStatePermitId>) => setFilterState((prev) => ({ ...prev, ...p }));

    const [advancedFilter, setAdvancedFilter] = useState<AdvancedFilterOptions>({
        isShowFrom: !!params.from,
        isShowTo: !!params.to,
        isShowPermitType: !!params.type,
        isShowRecurringType: !!params.recurringType,
        isShowStatus: !!params.status,
    });
    const setPartialAdvancedFilter = (p: Partial<AdvancedFilterOptions>) =>
        setAdvancedFilter((prev) => ({ ...prev, ...p }));

    const [initData, setInitData] = useState<DataFilterAll>({ companies: [], locations: [], regions: [] });
    const setPartialInitData = (p: Partial<DataFilterAll>) => setInitData((prev) => ({ ...prev, ...p }));

    useEffect(() => {
        // setState((p) => ({ ...p, filter: initFilterPermit }));
        getPermitTypes();
        if (isGoldRole()) {
            //drill down from companies
            getMyCompanies(true);
            getMyRegions(true);
            getMyLocations({ companyId: 0, regionId: 0, isSetInitData: true });

            if (params.locationId !== null) {
                getMyTenantByLocation(Number(params.locationId) ?? 0);
            }

            if (params.shopId !== null) {
                getMyZones({
                    locationId: Number(params.locationId) ?? 0,
                    permitId: 0,
                    tenantId: Number(params.shopId) ?? 0,
                });
            }
        } else if (isSilverRole()) {
            getMyLocations({ companyId: 0, regionId: 0, isSetInitData: true });
            if (params.locationId !== null) {
                getMyTenantByLocation(Number(params.locationId) ?? 0);
            }

            if (params.shopId !== null) {
                getMyZones({
                    locationId: Number(params.locationId) ?? 0,
                    permitId: 0,
                    tenantId: Number(params.shopId) ?? 0,
                });
            }
        } else if (isBronzeRole()) {
            //drill down from zone
            getMyZones({ locationId: 0, tenantId: account.id!, permitId: 0 });
        }
    }, []);

    useEffect(() => {
        handleChangeFilterToParams();
    }, [filterState, searchParams]);

    const handleChangeFilterToParams = () => {
        const newParams = new URLSearchParams({});
        if (filterState.companyId) newParams.append(ParamsFilterPermit.company, filterState.companyId.toString());
        if (filterState.locationId) newParams.append(ParamsFilterPermit.location, filterState.locationId.toString());
        if (filterState.regionId) newParams.append(ParamsFilterPermit.region, filterState.regionId.toString());
        if (filterState.shopId) newParams.append(ParamsFilterPermit.shop, filterState.shopId.toString());
        if (filterState.zoneIds?.length) {
            const handlerIdsParam = filterState.zoneIds.map((item) => encodeURIComponent(item)).join(',');
            newParams.append(ParamsFilterPermit.zones, handlerIdsParam);
        }
        if (filterState.validFrom)
            newParams.append(ParamsFilterPermit.validFrom, ConvertToGMT0Time(filterState.validFrom).toISOString());
        if (filterState.validTo)
            newParams.append(ParamsFilterPermit.validTo, ConvertToGMT0Time(filterState.validTo).toISOString());
        if (filterState.page && filterState.page !== 1) {
            newParams.append(ParamsFilterPermit.page, filterState.page.toString());
        }
        if (filterState.type && filterState.type !== '') newParams.append(ParamsFilterPermit.type, filterState.type);
        if (filterState.status && Number(filterState.status) !== StatusFilter.All)
            newParams.append(ParamsFilterPermit.status, String(filterState.status));
        if ((filterState.recurringType && filterState.recurringType !== null) || filterState.recurringType === 0)
            newParams.append(ParamsFilterPermit.recurringType, filterState.recurringType.toString());
        if (filterState.searchValue && filterState.searchValue !== '')
            newParams.append(ParamsFilterPermit.searchValue, filterState.searchValue);

        setSearchParams(newParams);
    };

    const getMyCompanies = (isSetInitData?: boolean) => {
        setPartialLoading({ isLoadingCompany: true });
        companiesController
            .getMyCompanyRF()
            .then((companies) => {
                setPartialData({ companies });
                isSetInitData && setPartialInitData({ companies });
            })
            .finally(() => setPartialLoading({ isLoadingCompany: false }));
    };

    const getPermitTypes = () => {
        const permitTypes: SelectItem[] = [];
        permitTypes.unshift({ value: '', label: t('permits:text.all') });
        permitController.getPermitTypes().then((res) => {
            if (res.data.length > 0) {
                res.data.map((item: any) => permitTypes.push({ value: item, label: item }));
                setPartialData({ permitTypes });
            }
        });
    };

    const getMyZones = (filterZoneProps: FilterZoneProps) => {
        setPartialLoading({ isLoadingZone: true });
        zoneController
            .getZonesDetail(filterZoneProps)
            .then((res) => {
                setPartialData({ zones: res });
            })
            .finally(() => setPartialLoading({ isLoadingZone: false }));
    };

    const getMyLocations = ({
        companyId,
        regionId,
        isSetInitData,
    }: {
        companyId: number;
        regionId: number;
        isSetInitData?: boolean;
    }) => {
        setPartialLoading({ isLoadingLocation: true });
        locationController
            .getAllRF({
                pageSize: 10000,
                page: 1,
                filter: { companyId, regionId },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                setPartialData({ locations: res.data });
                if (isSetInitData) {
                    setPartialInitData({ locations: res.data });
                    if (params.companyId !== null) {
                        setSelectField('company');
                        setPartialData({ locations: res.data.filter((l) => l.companyId === Number(params.companyId)) });
                    }
                }
            })
            .finally(() => setPartialLoading({ isLoadingLocation: false }));
    };

    const getMyRegions = (isSetInitData?: boolean) => {
        setPartialLoading({ isLoadingRegion: true });
        regionController
            .getAllRF(initFilterRegion)
            .then((res) => {
                setPartialData({ regions: res.data });
                isSetInitData && setPartialInitData({ regions: res.data });

                if (isSetInitData) {
                    setPartialInitData({ regions: res.data });
                    if (params.companyId !== null) {
                        setPartialData({ regions: res.data.filter((r) => r.companyId === Number(params.companyId)) });
                    }
                }
            })
            .then(() => setPartialLoading({ isLoadingRegion: false }));
    };

    useEffect(() => {
        if (isBronzeRole() && !filterState.shopId) return;
        if (isMobileSM && stateModalFilter.open) return;
        handleApplyFilter();
    }, [JSON.stringify(filterState)]);

    const getMyTenantByLocation = (locationId: number, isAutofill?: boolean) => {
        setPartialLoading({ isLoadingShop: true });
        shopController
            .filter({
                page: 1,
                pageSize: 1000,
                search: {
                    fields: [],
                    value: '',
                },
                filter: {
                    locationId: locationId,
                },
            })
            .then((res) => {
                const tenants = res.data;
                if (isAutofill) {
                    if (tenants.length > 0) {
                        setPartialData({ tenants: tenants });
                        setPartialFilter({ shopId: tenants.length === 1 ? tenants[0].clientId : undefined });
                    } else {
                        setPartialData({ zones: [] });
                        setPartialFilter({ shopId: undefined });
                    }
                } else {
                    setPartialData({ tenants: tenants });
                }
            })
            .finally(() => setPartialLoading({ isLoadingShop: false }));
    };

    const handleResetFilter = () => {
        setSelectField(undefined);
        setAdvancedFilter(_initAdvancedFilter);
        setFilterState({ status: StatusFilter.All });
        setPartialData({
            companies: initData.companies,
            locations: initData.locations,
            regions: initData.regions,
            zones: [],
            tenants: [],
        })
        onClickClearSearch();
    };

    const handleChangePage = (page: number) => {
        setPartialFilter({ page });
    };

    const handleRemoveAdvancedFilter = (name: AdvancedName) => {
        if (!isPermitsPage) return;
        if (name === 'permitType') {
            setPartialAdvancedFilter({ isShowPermitType: false });
            setPartialFilter({ type: '', page: 1 });
        } else if (name === 'status') {
            setPartialAdvancedFilter({ isShowStatus: false });
            setPartialFilter({ status: StatusFilter.All, page: 1 });
        } else if (name === 'validFrom') {
            setPartialAdvancedFilter({ isShowFrom: false });
            setPartialFilter({ validFrom: undefined, page: 1 });
        } else if (name === 'validTo') {
            setPartialAdvancedFilter({ isShowTo: false });
            setPartialFilter({ validTo: undefined, page: 1 });
        } else if (name === 'recurringType') {
            setPartialAdvancedFilter({ isShowRecurringType: false });
            setPartialFilter({ recurringType: null, page: 1 });
        }
    };

    const inputRef = useRef<HTMLInputElement | null>(null);
    const onChangeSearch = (e: any) => {
        setPartialFilter({ searchValue: e.target.value, page: 1 });
    };

    const onClickClearSearch = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            setPartialFilter({ searchValue: undefined });
        }
    };

    const handleChangeCompany = (company?: Company) => {
        setPartialFilter({
            companyId: company?.id,
            regionId: undefined,
            locationId: undefined,
            zoneIds: [],
            page: 1,
            shopId: undefined,
        });

        if (company) {
            setSelectField('company');
            setPartialData({
                locations: initData.locations.filter((l) => l.companyId === company?.id ?? 0),
                regions: initData.regions.filter((r) => r.companyId === company?.id ?? 0),
                tenants: [],
                zones: [],
            });
        } else {
            setSelectField(undefined);
            setPartialData({ locations: initData.locations, regions: initData.regions });
        }
    };

    const handleChangeRegion = (region?: Region) => {
        setPartialData({
            locations: initData.locations.filter((l) =>
                region ? l.regionId === region?.id : l.companyId === filterState.companyId
            ),
        });
        setPartialFilter({ regionId: region?.id, locationId: undefined, shopId: undefined, zoneIds: [], page: 1 });
    };

    const handleChangeLocation = (location?: LocationWithDetail) => {
        setPartialFilter({ locationId: location?.id, zoneIds: [], shopId: undefined, page: 1 });
        setPartialData({ zones: [], tenants: [] });

        if (selectField === 'company') {
            if (location) {
                //get tenant and zone
                setPartialFilter({ regionId: location.regionId });
                getMyTenantByLocation(location.id);
            }
        } else {
            if (location) {
                setSelectField('location');
                setPartialFilter({
                    regionId: location.regionId,
                    companyId: location.companyId,
                });
                setPartialData({
                    regions: initData.regions.filter((r) => r.id === location?.regionId),
                    companies: initData.companies.filter((c) => c.id === location?.companyId),
                    zones: [],
                });

                getMyTenantByLocation(location.id);
            } else {
                setSelectField(undefined);
                setPartialFilter({ locationId: undefined, zoneIds: [], regionId: undefined, companyId: undefined });
                setPartialData({
                    companies: initData.companies,
                    regions: initData.regions,
                    locations: initData.locations,
                });
            }
        }
    };

    const handleChangeTenant = (shop?: TenantWithAllocate) => {
        getMyZones({ locationId: 0, tenantId: shop?.clientId ?? 0, permitId: 0 });
        setPartialFilter({ shopId: shop?.clientId, page: 1, zoneIds: [] });
    };

    const handleChangeZone = (zones?: Zone[]) => {
        setPartialFilter({ zoneIds: zones?.map((z) => z.id) ?? [], page: 1 });
    };

    const getZones = () => {
        const zones: Zone[] = [];
        filterState.zoneIds?.forEach((z) => {
            const find = dataState.zones.find((zone) => zone.id === z);
            if (find) zones.push(find);
        });
        return zones;
    };

    const getDisableZone = () => {
        if (loading.isLoadingZone) return true;
        if (isBronzeRole()) return false;
        if (!filterState.locationId) return true;
        if (!filterState.shopId && !isBronzeRole()) return true;
    };

    const handleApplyFilter = () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        permitController
            .getList({
                page: filterState.page ?? 1,
                pageSize: 6,
                search: { fields: ['vrnNumber'], value: filterState.searchValue ?? '' },
                filter: {
                    companyId: filterState.companyId ?? 0,
                    regionId: filterState.regionId ?? 0,
                    locationId: filterState.locationId ?? 0,
                    shopId: isBronzeRole() ? account.id ?? 0 : filterState.shopId ?? 0,
                    zoneIds: filterState.zoneIds ?? [],
                    type: filterState.type ?? '',
                    status: Number(filterState.status) ?? StatusFilter.All,
                    validFrom: filterState.validFrom ? ConvertToGMT0Time(filterState.validFrom) : undefined,
                    validTo: filterState.validTo ? ConvertToGMT0Time(filterState.validTo) : undefined,
                    recurringType: filterState.recurringType ?? null,
                },
            })
            .then((res) => setState((prev) => ({ ...prev, permitPagination: res })))
            .finally(() => {
                setState((prev) => ({ ...prev, isLoading: false }));
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });
    };

    const FilterComponents = (
        <Grid
            item
            container
            xs={12}
            p={2}
            sx={{ background: color.grey100 }}
            borderRadius={'10px'}
            justifyContent={'space-between'}
        >
            {isMobileSM && (
                <ModalFilterPermits
                    open={stateModalFilter.open}
                    handleOpen={stateModalFilter.handleOpen}
                    handleClose={stateModalFilter.handleClose}
                    modalContent={
                        <FilterModalContent
                            handleClose={stateModalFilter.handleClose}
                            advancedFilter={advancedFilter}
                            dataState={dataState}
                            filterState={filterState}
                            loading={loading}
                            selectField={selectField}
                            setAdvancedFilter={setAdvancedFilter}
                            setPartialFilter={setPartialFilter}
                            handleChangeCompany={handleChangeCompany}
                            handleChangeLocation={handleChangeLocation}
                            handleChangeRegion={handleChangeRegion}
                            handleChangeTenant={handleChangeTenant}
                            handleChangeZone={handleChangeZone}
                            handleResetFilter={handleResetFilter}
                            handleApplyFilter={handleApplyFilter}
                        />
                    }
                />
            )}
            <Grid
                item
                container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    // transition: 'all 0.5s',
                }}
                justifyContent="space-between"
            >
                <Grid item xs={isMobileSM ? 8 : 6} display="flex" flexDirection={'row'} alignItems={'center'} gap={0.5}>
                    <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                    <Typography variant="body1" component={'span'} mx={0.5}>
                        {t('dashboard:button.filter')}{' '}
                    </Typography>
                    <Button variant="cancelSmall" onClick={handleResetFilter}>
                        {t('dashboard:button.resetFilter')}
                    </Button>
                </Grid>
                {isMobileSM ? (
                    <FilterMobileIcon style={{ cursor: 'pointer' }} onClick={stateModalFilter.handleOpen} />
                ) : (
                    <>
                        <Grid item xs={4}>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        height: 'auto',
                                    },
                                    '& .MuiInputBase-root': {
                                        fontFamily: fontInputAddPermits,
                                    },
                                }}
                                fullWidth
                                placeholder={t('permits:text.searchByVRN')}
                                inputRef={inputRef}
                                defaultValue={filterState.searchValue}
                                onChange={_.debounce((value) => onChangeSearch(value), 1000)}
                                inputProps={{
                                    maxLength: 20,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <IconSearch />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item container>
                            <Grid container item xs={12} mt={1} display={isBronzeRole() ? 'none' : 'block'}>
                                <IAutoCompleteLocation
                                    listData={dataState.locations}
                                    itemValue={dataState.locations.find((l) => l.id === filterState.locationId)}
                                    label={t('dashboard:input.location')}
                                    keyLabel={'name'}
                                    keyEqual={'id'}
                                    setFilter={handleChangeLocation}
                                    isHaveAllOptions={true}
                                    allOptionLabel="All locations"
                                    isLoading={loading.isLoadingLocation}
                                    disabled={loading.isLoadingLocation}
                                    keyStatus="locationStatus"
                                />
                            </Grid>

                            <Grid item container spacing={1.5} mt={0.5} xs={12}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    mt={{ xs: 2, sm: 0, md: 0 }}
                                    display={isGoldRole() ? 'block' : 'none'}
                                >
                                    <IAutoCompleteRF
                                        listData={dataState.companies}
                                        itemValue={dataState.companies.find((c) => c.id === filterState.companyId)}
                                        label={t('dashboard:input.company')}
                                        keyLabel={'displayName'}
                                        keyEqual={'id'}
                                        setFilter={handleChangeCompany}
                                        isHaveAllOptions={true}
                                        allOptionLabel="All companies"
                                        isLoading={loading.isLoadingCompany}
                                        disabled={
                                            loading.isLoadingCompany || selectField === 'location' || !isGoldRole()
                                        }
                                    />
                                </Grid>

                                <Grid item xs={4} sm={6} md={6} display={isGoldRole() ? 'block' : 'none'}>
                                    <IAutoCompleteRF
                                        listData={dataState.regions}
                                        itemValue={dataState.regions.find((r) => r.id === filterState.regionId)}
                                        label={'Region'}
                                        keyLabel={'name'}
                                        keyEqual={'id'}
                                        setFilter={handleChangeRegion}
                                        isHaveAllOptions={true}
                                        allOptionLabel="All regions"
                                        isLoading={loading.isLoadingRegion}
                                        disabled={
                                            loading.isLoadingRegion ||
                                            !filterState.companyId ||
                                            selectField === 'location'
                                        }
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    mt={{ xs: 2, sm: 0, md: 0 }}
                                    display={isBronzeRole() ? 'none' : 'block'}
                                >
                                    <IAutoCompleteRF
                                        listData={dataState.tenants}
                                        itemValue={dataState.tenants.find((t) => t.clientId === filterState.shopId)}
                                        label={'Tenant'}
                                        keyLabel={'tenantName'}
                                        keyEqual={'clientId'}
                                        setFilter={handleChangeTenant}
                                        isHaveAllOptions={true}
                                        allOptionLabel="All tenants"
                                        isLoading={loading.isLoadingShop}
                                        disabled={
                                            !filterState.locationId ||
                                            !dataState.tenants.length ||
                                            loading.isLoadingShop
                                        }
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={isSilverRole() ? 6 : 12}
                                    md={isBronzeRole() ? 12 : isSilverRole() ? 6 : 6}
                                    mt={{ xs: 0, sm: 0, md: 0 }}
                                >
                                    <IMultiSelectWithCheckBoxRF
                                        limitTags={3}
                                        label={t('dashboard:input.zone')}
                                        keyId={'id'}
                                        keyLabel={'publicName'}
                                        listData={dataState.zones}
                                        isDisable={getDisableZone()}
                                        value={getZones()}
                                        setFilter={(zones) => {
                                            handleChangeZone(zones);
                                        }}
                                        chipSize={300}
                                        placeHolder={
                                            filterState.zoneIds?.length ? undefined : t('permits:input.AllZones')
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                {!isMobileSM && (
                    <AdvancedFilterLarge
                        handleRemoveAdvancedFilter={handleRemoveAdvancedFilter}
                        setAdvancedFilter={setAdvancedFilter}
                        advancedFilter={advancedFilter}
                        setPartialFilter={setPartialFilter}
                        filterState={filterState}
                        dataState={dataState}
                    />
                )}
            </Grid>
        </Grid>
    );

    return { ...state, dataState, filterState, FilterComponents, handleChangePage, handleResetFilter };
}

export const PermitFilterContext = React.createContext<ReturnType<typeof usePermitsFilter>>({} as any);

export interface TenantSearch {
    id: number;
    name: string;
    email: string;
}

const initFilterRegion = {
    pageSize: 10000,
    page: 1,
    filter: { companyId: 0 },
    search: {
        fields: [],
        value: '',
    },
};

const initFilterLocation = {
    pageSize: 10000,
    page: 1,
    filter: { companyId: 0, regionId: 0 },
    search: {
        fields: [],
        value: '',
    },
};
